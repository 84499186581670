import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, DirectionAndPlacement, HelpTextAnimated, Style } from '@pointdotcom/pds';
import { pages } from 'containers/helpers';
import ModalPage from 'containers/prequal/ModalPage';
import { useRedirectToBeginning } from 'containers/prequal/hooks';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { getPrequalPageFlow } from 'containers/prequal/productPageFlow';
import { useHistory } from 'containers/routerHelpers';
import { answerCoop } from 'store/property';
import i18n from './i18n';

const CoopProperty = () => {
  useRedirectToBeginning();
  const { product } = useProduct();
  const existing = useSelector((state) => state.property?.isCoop);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState(existing || '');
  const [formError, setFormError] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { nextPage, totalPages, flowIndex } = getPrequalPageFlow(
    product,
    pages.PREQUAL_COOP_PROPERTY
  );

  const clearErrors = () => {
    setFormError(null);
  };

  const handleChange = (e, { value }) => {
    clearErrors();
    setAnswer(value);
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (!answer) {
        setFormError(i18n.thisField);
        return;
      }

      const isCoop = answer === 'yes';

      dispatch(answerCoop(isCoop));
      history.push(nextPage({ isCoop }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalPage
      pageName={i18n.pageName}
      numIndicators={totalPages}
      currentIndicatorIndex={flowIndex}
      content={{
        title: i18n.title,
        subtitle: i18n.subtitle,
      }}
    >
      <form noValidate onSubmit={handleSubmit}>
        <Checkbox label="Yes" value="yes" onChange={handleChange} checked={answer === 'yes'} />
        <Checkbox label="No" value="no" onChange={handleChange} checked={answer === 'no'} focused />
        <Button block type="submit" loading={loading}>
          {i18n.continue}
        </Button>
      </form>
      <HelpTextAnimated
        show={!!formError}
        styleMarginPosition={DirectionAndPlacement.Top}
        styleType={Style.Error}
      >
        {formError}
      </HelpTextAnimated>
    </ModalPage>
  );
};

export default CoopProperty;
