import React, { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AnyEvent,
  AutocompleteSelectionType,
  Button,
  DirectionAndPlacement,
  HelpTextAnimated,
  Style,
  Validation,
  messagingI18n,
} from '@pointdotcom/pds';
import SmartyStreetsAutocomplete from 'components/SmartyStreetsAutocomplete';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { useUtmParameters } from 'containers/prequal/hooks';
import { NotInServiceAreaError, verifyServiceArea } from 'containers/prequal/pages/utils';
import { getNextPage } from 'containers/prequal/productPageFlow';
import { useHistory } from 'containers/routerHelpers';
import { AddressField, getAddressStructureFromParser } from 'lib/smartyStreets';
import { useLazyGetProductsForServiceAreaQuery } from 'services/api/prequalApi';
import { Products } from 'store/constants';
import { answerAddress } from 'store/property';
import i18n from './i18n';

const HomeAddressForm = ({
  product = Products.HEI,
  placeholder = i18n.yourHomeAddress,
  buttonText = i18n.continue,
  onFocus = () => null,
  onBlur = () => null,
  submitOnSelect = true,
  inputProps = {},
}) => {
  useUtmParameters();

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [fieldError, setFieldError] = useState<string | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [getProductsForAddress] = useLazyGetProductsForServiceAreaQuery();
  const isValid = () => {
    if (value) {
      if (typeof value === 'string') {
        return value.replace(/\s/g, '').length > 0;
      }
      return true;
    }
    return false;
  };

  const clearErrors = () => {
    setFieldError(null);
    setFormError(null);
  };

  const handleFocus = () => {
    clearErrors();
    if (onFocus) {
      onFocus();
    }
  };

  const handleSubmit = async (event?: FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    if (loading) {
      return;
    }

    if (!isValid()) {
      setFieldError(Validation.i18n.fieldRequired);
      return;
    }

    setLoading(true);

    try {
      const structuredAddress = getAddressStructureFromParser(value);
      const { streetAddress, city, state, zip } = structuredAddress;
      let confirmFullAddress = false;

      // If the address is not complete send the user to the confirmation page
      if (!(streetAddress && city && state && zip)) {
        structuredAddress[AddressField.CONFIRMATION_REQUIRED] = true;
        confirmFullAddress = true;
      }
      dispatch(answerAddress(structuredAddress));

      // Verify product availability
      if (!confirmFullAddress) {
        const serviceAreaResponse = await getProductsForAddress(structuredAddress).unwrap();
        if (serviceAreaResponse.addressConfirmationRequired) {
          confirmFullAddress = true;
          if (serviceAreaResponse.matchedAddress) {
            dispatch(answerAddress(serviceAreaResponse.matchedAddress));
          }
        } else {
          // This will throw if the product is not available
          verifyServiceArea(product, serviceAreaResponse.availableProducts);
        }
      }

      // Head to the next page
      if (confirmFullAddress) {
        const confirmationPage = generateUrlFromPage(pages.PREQUAL_UNIT_NUMBER, { product });
        history.push(confirmationPage);
      } else {
        history.push(getNextPage(product, pages.PREQUAL_HOME_ADDRESS));
      }
    } catch (e) {
      if (e instanceof NotInServiceAreaError) {
        history.push(generateUrlFromPage(pages.PREQUAL_WAITLIST_SIGNUP, { product }));
        return;
      }
      setFormError(messagingI18n.errors.formError);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: AnyEvent, { value: newValue }: { value: string }) => {
    setValue(newValue);
  };

  const handleSelect = ({
    value: newValue,
    selectionType,
  }: {
    value: string;
    selectionType: AutocompleteSelectionType;
  }) => {
    setValue(newValue);
    if (submitOnSelect && selectionType === AutocompleteSelectionType.Explicit) {
      handleSubmit();
    }
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <SmartyStreetsAutocomplete
          placeholder={placeholder}
          error={!!fieldError}
          helptext={fieldError}
          inputProps={inputProps}
          onFocus={handleFocus}
          onBlur={onBlur}
          onSelect={handleSelect}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
        <Button block type="submit" loading={loading}>
          {buttonText}
        </Button>
      </form>
      <HelpTextAnimated
        show={!!formError}
        styleMarginPosition={DirectionAndPlacement.Top}
        styleType={Style.Error}
      >
        {formError}
      </HelpTextAnimated>
    </>
  );
};

export default HomeAddressForm;
