import React, { useEffect, useMemo, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { DirectionAndPlacement, useScrollSpy } from '@pointdotcom/pds';
import {
  getBannerProps,
  getText as getBannerText,
} from 'components/Banners/ResumeApplicationBanner';
import ContactModal from 'components/EmailOfferContactModal';
import FooterNav from 'components/FooterNav';
import FullScreenLoading from 'components/FullScreenLoading';
import { BannerMessageType, GenericBannerProps } from 'components/GenericMessageBanner';
import HeiTermsFooter from 'components/HeiTermsFooter';
import MainHeader, { HeaderType } from 'components/MainHeader';
import { MainHeaderRefs } from 'components/MainHeader/constants';
import { SubHeaderContentType } from 'components/SubHeader';
import { TagPage, Workflow } from 'components/TagPage';
import ErrorPage, { ErrorType } from 'containers/ErrorPage';
import BookingAndNavFooter, {
  BookingAndNavFooterProps,
  ButtonPostApplicationLabel,
} from 'containers/EstimatorPage/BookingAndNavFooter';
import { Page } from 'containers/helpers';
import { useProduct } from 'containers/prequal/hooks';
import { useCalendar } from 'containers/prequal/hooks/useCalendar';
import { useParams } from 'containers/routerHelpers';
import { useGetVisitorDataQuery, useLazyGetHeiApplicationUrlQuery } from 'services/api/prequalApi';
import { RootState } from 'store';
import { getApplicantModel } from 'store/applicant';
import { Products } from 'store/constants';
import {
  getEstimatesAreLoading,
  getEstimatesError,
  getEstimatesHaveError,
  getHEIEstimateModel,
  getPriceRangeModel,
} from 'store/estimates';
import { getBannerWasDismissed } from 'store/general';
import { getEstimate, getPricingRange } from 'store/thunks/estimates';
import { useHeiNavItems } from '../heiNavigation';
import FloatingCTA from './FloatingCTA';
import HeiOfferEstimatorPageCapModal from './HeiOfferEstimatorPageCapModal';
import HeiOfferEstimatorPageFooterSection from './HeiOfferEstimatorPageFooterSection';
import HeiOfferEstimatorPageGoodNewsSection from './HeiOfferEstimatorPageGoodNewsSection';
import HeiOfferEstimatorPageHPCSection from './HeiOfferEstimatorPageHPCSection';
import HeiOfferEstimatorPagePricingSection from './HeiOfferEstimatorPagePricingSection';
import HeiOfferEstimatorPageScenariosSection from './HeiOfferEstimatorPageScenariosSection';
import i18n from './i18n';
import * as styles from './styles';

export enum OfferEstimatorPageLayout {
  SinglePage,
  OfferPage,
  EstimatorPage,
}

interface HeiOfferEstimatorPageProps {
  includeContent?: (Page.HEI_OFFER | Page.HEI_ESTIMATOR)[];
}

type FooterWithCTAProps = Omit<BookingAndNavFooterProps, 'buttonLabel'>;

function FooterWithCTA(props: FooterWithCTAProps) {
  return (
    <BookingAndNavFooter {...props} buttonLabel={ButtonPostApplicationLabel.ContinueApplication} />
  );
}

export default function HeiOfferEstimatorPage({
  includeContent = [Page.HEI_OFFER, Page.HEI_ESTIMATOR],
}: HeiOfferEstimatorPageProps): JSX.Element {
  const dispatch = useDispatch();

  const { estimateKey } = useParams();
  const { product = Products.HEI } = useProduct();
  const applicant = useSelector(getApplicantModel);
  const estimate = useSelector(getHEIEstimateModel);
  const estimateIsLoading = useSelector(getEstimatesAreLoading);
  const estimateHasError = useSelector(getEstimatesHaveError);
  const estimateError = useSelector(getEstimatesError);
  const { data: visitor } = useGetVisitorDataQuery();
  const [capModalIsOpen, setCapModalIsOpen] = useState<boolean>(false);
  const mainHeaderRef = useRef<MainHeaderRefs | null>(null);
  const { scrollIsPastSpyBottom } = useScrollSpy({
    spyOn: mainHeaderRef.current?.bannerRef,
  });
  const bannerWasDismissed = useSelector(getBannerWasDismissed);

  const [getHeiApplicationUrl] = useLazyGetHeiApplicationUrlQuery();
  // Get the priceEstimate
  const pricingEstimateLoadNotStarted = useSelector((state: RootState) => {
    return !state.estimates.estimateLoadState;
  });
  useEffect(() => {
    if (!estimateKey || !pricingEstimateLoadNotStarted || estimate) {
      return;
    }
    dispatch(getEstimate({ estimateKey }));
  }, [estimateKey, estimate, pricingEstimateLoadNotStarted, dispatch]);

  // Get the priceRangeEstimate
  const pricingRange = useSelector(getPriceRangeModel);
  const pricingRangeEstimateLoadNotStarted = useSelector(
    (state: RootState) => !state.estimates[Products.HEI].pricingRangeLoadState
  );
  useEffect(() => {
    if (!estimateKey || !pricingRangeEstimateLoadNotStarted || pricingRange) {
      return;
    }
    dispatch(getPricingRange({ estimateKey }));
  }, [estimateKey, pricingRange, pricingRangeEstimateLoadNotStarted, dispatch]);

  // get the nav items and props
  const navProps = {
    estimateKey,
  };

  const { calendar } = useCalendar({
    forStateAbbr: estimate?.property?.address?.state,
    forPartners: estimate?.usePartnerSources(),
  });

  const navItems = useHeiNavItems({ estimate });

  const bannerProps: GenericBannerProps = useMemo(() => {
    // Decide which banner to show
    if (visitor?.hasActiveDocket) {
      const [message, actionText] = getBannerText(visitor);
      if (actionText) {
        const isVisible = true;
        return getBannerProps(isVisible, {
          message: message ?? undefined,
          actionText,
          getHeiApplicationUrl,
        });
      }
    }
    if (estimate?.preliminary) {
      return {
        messageType: BannerMessageType.Preliminary,
      };
    }
    return {};
  }, [estimate?.preliminary, visitor, getHeiApplicationUrl]);

  if (estimateIsLoading) {
    return <FullScreenLoading />;
  }

  if (estimateHasError) {
    return (
      <ErrorPage
        errorType={
          String(estimateError?.status) === '404' ? ErrorType.FourOhFour : ErrorType.General
        }
        product={product}
      />
    );
  }

  // If the estimate is expired
  if (estimate?.getIsExpired() === true) {
    return <ErrorPage errorType={ErrorType.OfferExpired} />;
  }

  return (
    <>
      <styles.HEIOfferEstimatorPageStyle>
        <Helmet title={i18n.pageTitle} />
        <TagPage
          page={`Offer/Estimator Page (${includeContent.join('+')})`}
          workflow={Workflow.PrequalOffer}
        />
        <MainHeader
          ref={mainHeaderRef}
          bannerProps={bannerProps}
          navProps={navProps}
          navItems={navItems}
          headerType={HeaderType.Progress}
          showSubHeader
          subHeaderProps={{
            calendar,
            content: SubHeaderContentType.CallUs,
            styleAlign: DirectionAndPlacement.Right,
          }}
        />
        {includeContent.includes(Page.HEI_OFFER) && estimate != null && (
          <>
            <HeiOfferEstimatorPageGoodNewsSection applicant={applicant} estimate={estimate} />
            <HeiOfferEstimatorPagePricingSection pricingRange={pricingRange} estimate={estimate} />
            <HeiOfferEstimatorPageHPCSection onOpenCapModal={() => setCapModalIsOpen(true)} />
          </>
        )}
        {includeContent.includes(Page.HEI_ESTIMATOR) ? (
          <>
            <HeiOfferEstimatorPageScenariosSection
              estimate={estimate}
              pricingRange={pricingRange}
              bordered={includeContent.includes(Page.HEI_OFFER)}
            />
            <FooterWithCTA
              estimate={estimate}
              navItems={navItems}
              navProps={navProps}
              calendar={calendar}
            />
          </>
        ) : (
          <FooterNav
            navItems={navItems}
            navProps={navProps}
            bordered
            styleAlign={DirectionAndPlacement.Center}
          />
        )}
        <HeiOfferEstimatorPageFooterSection
          applicant={applicant}
          estimate={estimate}
          calendar={calendar}
          onOpenCapModal={() => setCapModalIsOpen(true)}
        />
        <HeiTermsFooter />
      </styles.HEIOfferEstimatorPageStyle>
      {estimateKey != null && <ContactModal applicant={applicant} estimateKey={estimateKey} />}
      {estimate != null && (
        <HeiOfferEstimatorPageCapModal
          estimate={estimate}
          isOpen={capModalIsOpen}
          onModalClose={() => setCapModalIsOpen(false)}
        />
      )}
      <FloatingCTA show={!bannerProps.show || bannerWasDismissed || scrollIsPastSpyBottom} />
    </>
  );
}
