import { TransitionStatus } from 'react-transition-group';
import styled, { createGlobalStyle, css } from 'styled-components';
import { DirectionAndPlacement, HeaderStyles } from '@pointdotcom/pds';

export interface FollowSectionStyleProps {
  animationStatus: TransitionStatus;
  placement?: DirectionAndPlacement.Top | DirectionAndPlacement.Bottom;
}

export const FollowBodyStyle = createGlobalStyle<
  { padding: string; show: boolean } & Pick<FollowSectionStyleProps, 'placement'>
>`
  body {
    transition: padding 200ms ease-in-out;
    ${({ placement, padding, show }) => {
      if (!show) {
        return null;
      }

      return placement === DirectionAndPlacement.Top
        ? css`
            padding-top: ${padding};
          `
        : css`
            padding-bottom: ${padding};
          `;
    }}
  }
`;

export const FollowSectionStyle = styled.div.attrs({
  className: 'FollowSectionStyle',
})<FollowSectionStyleProps>`
  transition: all 200ms ease-in-out;
  z-index: 5;

  ${({ placement }) =>
    placement === DirectionAndPlacement.Top
      ? css`
          top: 0;
        `
      : css`
          bottom: 0;
        `}

  left: 0;
  width: 100%;
  position: fixed;
  border-top-color: transparent;
  border: none;
  box-shadow: 0 0 10px ${({ theme }) => theme.line.color.default};
  padding: 0;
  background: white;

  ${({ animationStatus, placement }) => {
    const isEnteringAnimation = animationStatus === 'entering' || animationStatus === 'entered';
    const isExitingAnimation = animationStatus === 'exiting' || animationStatus === 'exited';
    const hidePositionY = placement === DirectionAndPlacement.Top ? '-100%' : '100%';
    if (isExitingAnimation) {
      return css`
        transform: translateY(${hidePositionY});
        opacity: 0;
      `;
    }

    if (isEnteringAnimation) {
      return css`
        transform: translateY(0);
        opacity: 1;
      `;
    }

    return null;
  }};
  @media (max-width: ${(props) => props.theme.responsive.largestTabletScreen}) {
    ${HeaderStyles.HeaderStyle} {
      margin-bottom: 0.3rem;
      h4 {
        font-size: 0.8rem;
      }
    }
  }
`;
