import React from 'react';
import { useSelector } from 'react-redux';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import FollowSection, { FollowSectionProps } from 'components/FollowSection';
import { CallUsLink } from 'components/SubHeader';
import {
  ButtonPostApplicationLabel,
  ButtonPostApplicationWithMessaging,
} from 'containers/EstimatorPage/BookingAndNavFooter';
import { getHEIEstimateModel } from 'store/estimates';
import * as styles from './styles';

export default function FloatingCTA({ show }: Pick<FollowSectionProps, 'show'>) {
  const estimate = useSelector(getHEIEstimateModel);

  return (
    <FollowSection show={show} placement={DirectionAndPlacement.Bottom} adjustBody>
      <styles.FloatingContainerStyle>
        <CallUsLink />
        {estimate && (
          <ButtonPostApplicationWithMessaging
            estimate={estimate}
            buttonLabel={ButtonPostApplicationLabel.ContinueApplication}
          />
        )}
      </styles.FloatingContainerStyle>
    </FollowSection>
  );
}
