import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  DirectionAndPlacement,
  HelpTextAnimated,
  Input,
  InputMask,
  InputMaskFormatter,
  InputMaskType,
  Style,
  Validation,
  messagingI18n,
} from '@pointdotcom/pds';
import { pages } from 'containers/helpers';
import ModalPage from 'containers/prequal/ModalPage';
import { useRedirectToBeginning } from 'containers/prequal/hooks';
import { usePrequalApplication } from 'containers/prequal/hooks/usePrequalApplication';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { getPrequalPageFlow } from 'containers/prequal/productPageFlow';
import { useHistory } from 'containers/routerHelpers';
import useClampConfirmation from 'hooks/useClampConfirmation';
import bugsnagClient from 'lib/bugsnagClient';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { answerMortgageBalance } from 'store/property';
import { PREQUAL_FLOW_TYPE } from 'types';
import i18n from './i18n';

const currencyMask = new InputMask({
  type: InputMaskType.Number,
  options: { format: InputMaskFormatter.Currency, precision: 0 },
});
const MAX_VALUE = 10000000;

const MortgageBalance = () => {
  useRedirectToBeginning();
  const { product } = useProduct();
  const { mortgageBalance: existing } = useSelector((state) => state.property);
  const { nextPage, totalPages, flowIndex } = getPrequalPageFlow(
    product,
    pages.PREQUAL_MORTGAGE_BALANCE
  );
  const flow = useSelector((state) => state.prequalApplication?.flow);
  const isDirectMailFirmOffer = [
    PREQUAL_FLOW_TYPE.FIRM_OFFER,
    PREQUAL_FLOW_TYPE.ALT_FIRM_OFFER,
  ].includes(flow);

  const [value, setValue] = useState(existing ?? '');
  const [loading, setLoading] = useState(false);
  const [fieldError, setFieldError] = useState(null);
  const [formError, setFormError] = useState(null);
  const { onChange, onSubmit, shouldBlockSubmitUntilConfirm, errorText, buttonText, buttonStyle } =
    useClampConfirmation({
      formattedMaxValue: currencyMask.getFormatted(MAX_VALUE),
      defaultErrorText: fieldError,
      defaultButtonText: i18n.continue,
    });

  const history = useHistory();
  const dispatch = useDispatch();
  const prequalContactFlagEnabled = useFeatureFlag(FeatureFlag.PrequalContact) === 'test';
  const { postPrequalApplication, processApplicationResponse } = usePrequalApplication();

  const getValue = () => currencyMask.getUnformatted(value);
  const isValid = () => getValue().replace(/\s/g, '').length > 0;

  const clearErrors = () => {
    setFieldError(null);
    setFormError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    onSubmit(e);
    if (shouldBlockSubmitUntilConfirm) {
      return;
    }

    try {
      if (!isValid()) {
        setFieldError(Validation.i18n.fieldRequired);
        return;
      }

      setLoading(true);
      dispatch(answerMortgageBalance(getValue()));

      if (isDirectMailFirmOffer) {
        history.push(nextPage());
      } else {
        // Save mortgage balance and re-submit application
        const result = await postPrequalApplication();
        processApplicationResponse(result);
      }
    } catch (error) {
      if (error instanceof Error) {
        bugsnagClient.notify(error);
      }
      setFormError(messagingI18n.errors.formError);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e, { value: formattedValue, clamped, typing }) => {
    onChange(e, { clamped, typing });
    setValue(formattedValue);
  };

  const contextHeadline = prequalContactFlagEnabled ? i18n.whyDoesPoint : i18n.whatIfIHave;
  const contextBody = prequalContactFlagEnabled ? i18n.pointNeedsTo : i18n.youMayStill;

  return (
    <ModalPage
      pageName={i18n.pageName}
      numIndicators={totalPages}
      currentIndicatorIndex={flowIndex}
      titleMaxWidth="475px"
      content={{ title: i18n.whatsTheMortgage, subtitle: i18n.itsOkay }}
      contextHelp={
        contextHeadline && contextBody
          ? [
              {
                headline: contextHeadline,
                body: contextBody,
              },
            ]
          : []
      }
    >
      <form noValidate onSubmit={handleSubmit}>
        <Input
          error={!!errorText}
          placeholder={i18n.yourMortgage}
          mask={InputMaskType.Number}
          maskOptions={{ format: InputMaskFormatter.Currency }}
          step={10000}
          onChange={handleChange}
          onFocus={clearErrors}
          value={value}
          helptext={errorText}
          min={0}
          max={MAX_VALUE}
          focused
        />
        <Button block type="submit" styleType={buttonStyle} loading={loading}>
          {buttonText}
        </Button>
      </form>
      <HelpTextAnimated
        show={!!formError}
        styleMarginPosition={DirectionAndPlacement.Top}
        styleType={Style.Error}
      >
        {formError}
      </HelpTextAnimated>
    </ModalPage>
  );
};

export default MortgageBalance;
