import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { PolicyType, messagingI18n } from '@pointdotcom/pds';
import ContactForm, { ContactFormField } from 'components/ContactForm';
import { pages } from 'containers/helpers';
import WhiteContainer from 'containers/prequal/WhiteContainer';
import { useRedirectToBeginning } from 'containers/prequal/hooks';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { getNextPage } from 'containers/prequal/productPageFlow';
import { useHistory } from 'containers/routerHelpers';
import { usePostWaitlistMutation } from 'services/api/prequalApi';
import { clearApplication } from 'store/actions';
import i18n from './i18n';

const WaitlistSignup = () => {
  useRedirectToBeginning();
  const { product } = useProduct();
  const history = useHistory();
  const dispatch = useDispatch();
  const propertyAddress = useSelector((state) => state.property?.address);
  const applicant = useSelector((state) => state.applicant);
  const [postWaitlist] = usePostWaitlistMutation();

  const handleSubmitValid = async (e, { setLoading, setFormError, value }) => {
    const { firstName, lastName, phone, email } = value;
    setLoading(true);

    try {
      const waitlistPayload = {
        firstName,
        lastName,
        phone,
        email,
        address: propertyAddress,
        product,
      };
      await postWaitlist(waitlistPayload).unwrap();
      history.push(getNextPage(product, pages.PREQUAL_WAITLIST_SIGNUP));
      dispatch(clearApplication({ product }));
    } catch (error) {
      setFormError(messagingI18n.errors.formError);
    } finally {
      setLoading(false);
    }
  };

  const hiddenFields = useMemo(() => {
    const fields = [];
    if (applicant.firstName) {
      fields.push(ContactFormField.FirstName);
    }
    if (applicant.lastName) {
      fields.push(ContactFormField.LastName);
    }
    if (applicant.email) {
      fields.push(ContactFormField.EmailAddress);
    }
    if (applicant.phone) {
      fields.push(ContactFormField.PhoneNumber);
    }
    return fields;
  }, [applicant.firstName, applicant.lastName, applicant.email, applicant.phone]);

  const title = i18n.waitlistAlert;
  const subtitle = i18n.pleaseJoin;

  return (
    <>
      <Helmet title={i18n[`${product}PageTitle`]} />
      <WhiteContainer content={{ title, subtitle }} align="left" contentContainerSize="small">
        <ContactForm
          hiddenFields={hiddenFields}
          firstName={applicant.firstName}
          lastName={applicant.lastName}
          email={applicant.email}
          phone={applicant.phone}
          buttonText={i18n.joinWaitlist}
          policies={[PolicyType.Contact]}
          onSubmitValid={handleSubmitValid}
        />
      </WhiteContainer>
    </>
  );
};

export default WaitlistSignup;
