import styled, { css } from 'styled-components';
import {
  DropdownStyles,
  HeaderStyles,
  LinkButtonStyles,
  RangeStyles,
  mixins,
} from '@pointdotcom/pds';
import { TableHeaderStyle } from 'containers/EstimatorPage/ScenariosTable/styles';
import { pxToRem } from 'styleHelpers';

export const ScenariosSectionStyle = styled.section.attrs({
  className: 'ScenariosSectionStyle',
})<{
  bordered?: boolean;
}>`
  ${HeaderStyles.HeaderStyle} {
    margin-bottom: 19px;

    > h2 {
      @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
        font-size: ${pxToRem(39)};
      }
      @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
        font-size: ${pxToRem(30)};
      }
    }

    > h1 {
      @media (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
        font-size: 4.5rem;
      }
      @media not (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
        font-size: 3.125rem;
      }
    }
  }

  ${DropdownStyles.DropdownStyle} {
    margin: 0;
  }
`;

export const DividerStyle = styled.hr.attrs({ className: 'DividerStyle' })`
  background: ${({ theme }) => theme.line.color.default};
  margin: 0;
  align-self: stretch;
  flex-shrink: 0;
`;

export const RangesStyle = styled.div.attrs({ className: 'RangesStyle' })<{
  numberedSlidersStyle: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(60)};
  > ${DividerStyle} {
    display: none;
  }
  counter-reset: sliders;

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    gap: ${pxToRem(23)};
  }

  @media not (min-width: ${({ theme }) => theme.responsive.tabletBreakpoint}) {
    ${({ numberedSlidersStyle }) =>
      numberedSlidersStyle
        ? css`
            gap: 3.12rem;

            > ${DividerStyle} {
              display: block;
              width: auto;
              height: 1px;
            }
          `
        : ''}
  }
`;

export const HeroTextContainerStyle = styled.div.attrs({ className: 'HeroTextConatinerStyle' })``;

export const GridStyle = styled.div.attrs({ className: 'GridStyle' })<{
  numberedSlidersStyle: boolean;
  longDivider: boolean;
}>`
  display: flex;
  gap: ${({ numberedSlidersStyle }) => (numberedSlidersStyle ? '3.38rem' : '5rem')};
  align-items: center;
  justify-content: space-between;

  > ${DividerStyle} {
    width: 1px;
    height: auto;
  }

  @media not (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    > :not(${DividerStyle}) {
      flex-basis: 50%;
      flex-grow: 1;
    }

    ${HeroTextContainerStyle} {
      padding: 2rem 0;
    }

    ${({ numberedSlidersStyle }) =>
      numberedSlidersStyle
        ? css`
            > ${RangesStyle} {
              flex-basis: 75%;
            }
          `
        : ''}
  }

  @media (max-width: ${({ theme }) => theme.responsive.largestTabletScreen}) {
    flex-direction: column;
    align-items: stretch;
    gap: ${({ longDivider }) => (longDivider ? '3.12rem' : '32px')};

    > ${DividerStyle} {
      width: auto;
      height: 1px;
      ${({ longDivider, theme }) =>
        longDivider
          ? css`
              box-shadow:
                -50vw 0 ${theme.line.color.default},
                50vw 0 ${theme.line.color.default};
            `
          : ''}
    }
  }
`;

export const SliderNumberStyle = styled.div.attrs({
  className: 'SliderNumberStyle',
})`
  font-size: 6.25rem;
  font-family: ${({ theme }) => theme.fonts.serif.name};
  line-height: 1.125;
  color: ${({ theme }) => theme.line.color.default};

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    font-size: 4.375rem;
    margin-bottom: -0.2em;
  }

  width: 0.75em;
  height: 0.8em;

  position: relative;

  &::after {
    content: counter(sliders) '.';
    counter-increment: sliders;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

export const CurrentOfferContainerStyle = styled.div.attrs({
  className: 'CurrentOfferContainerStyle',
})`
  ${LinkButtonStyles.ButtonLinkStyle} {
    margin-top: 1.25rem;

    text-decoration: none;

    ${mixins.desktopHoverCondition()} {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const RangeSliderStyle = styled.div.attrs({ className: 'RangeSliderStyle' })<{
  numberedSliders: boolean;
  isFixedValue?: boolean;
}>`
  ${({ numberedSliders, theme }) =>
    numberedSliders
      ? css`
          display: grid;
          grid-template-columns: min-content auto;

          @media (min-width: ${theme.responsive.tabletBreakpoint}) {
            align-items: center;
            column-gap: 1.38rem;
            row-gap: 1.25rem;

            ${SliderNumberStyle} {
              grid-row: span 2;
            }
            ${RangeStyles.RangeInputStyle} {
              padding-bottom: 1.12rem;
            }
          }
          @media (max-width: ${theme.responsive.largestMobileScreen}) {
            column-gap: 1.12rem;
            row-gap: 1.4rem;
            align-items: center;

            ${RangeStyles.RangeInputStyle} {
              grid-column: span 2;
            }
          }
          ${HeaderStyles.HeaderStyle} {
            margin: 0;
          }
        `
      : ''}
`;

export const ScenariosTableStyle = styled.div.attrs({ className: 'ScenariosTableStyle' })`
  isolation: isolate;

  ${TableHeaderStyle} {
    background: ${({ theme }) => theme.Color.White};
    border-top: none;
  }
`;

export const ScenariosTableTopShadowStyle = styled.div.attrs({
  className: 'ScenariosTableTopShadowStyle',
})`
  z-index: -1;
  height: 0;

  &::after {
    display: block;
    content: '';
    height: 36px;
    box-shadow: 0px 14px 26px 1px ${({ theme }) => mixins.rgba(theme.line.color.default, 0.67)};
  }
`;

export const LargeOfferAmountStyle = styled.div.attrs({ className: 'LargeOfferAmountStyle' })`
  font-family: ${({ theme }) => theme.fonts.serif.name};
  font-size: 6rem;
  font-weight: 400;
  line-height: 0.75;
  /* margin-bottom: 0.7em; */

  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    /* margin-bottom: 0.25em; */
    font-size: 4.375rem;
  }
`;
