import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, DirectionAndPlacement, HelpTextAnimated, Style } from '@pointdotcom/pds';
import { getPathFromPage, pages } from 'containers/helpers';
import ModalPage from 'containers/prequal/ModalPage';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { getPrequalPageFlow } from 'containers/prequal/productPageFlow';
import { useHistory } from 'containers/routerHelpers';
import { clearApplication } from 'store/actions';
import { PREQUAL_FLOW_TYPE } from 'types';
import i18n from './i18n';

const ConfirmName = () => {
  const { product } = useProduct();
  const history = useHistory();
  const dispatch = useDispatch();
  const { nextPage, totalPages, flowIndex } = getPrequalPageFlow(
    product,
    pages.PREQUAL_CONFIRM_NAME
  );
  const applicant = useSelector((state) => state.applicant);
  const isItaFlow = useSelector(
    (state) => state.prequalApplication?.flow === PREQUAL_FLOW_TYPE.INVITATION_TO_APPLY
  );
  const hasMortgageBalance = useSelector(
    (state) => state.prequalApplication?.directMailData?.hasMortgageBalance === true
  );

  const [answer, setAnswer] = useState(null);
  const [formError, setFormError] = useState(null);

  const applicantName =
    applicant?.firstName && applicant?.lastName
      ? `${applicant.firstName} ${applicant.lastName}`
      : null;

  // Redirect to HEI start page if applicant is not found
  useEffect(() => {
    if (!applicantName) {
      dispatch(clearApplication({ product }));
      history.replace(getPathFromPage(pages.PREQUAL_DEFAULT));
    }
  }, [applicantName, history, product, dispatch]);

  const clearErrors = () => {
    setFormError(null);
  };

  const handleChange = (e, { value }) => {
    clearErrors();
    setAnswer(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (answer?.toLowerCase() === 'yes') {
      history.push(nextPage({ isItaFlow, hasMortgageBalance }));
    } else if (answer?.toLowerCase() === 'no') {
      dispatch(clearApplication({ product }));
      // The clearApplication() in the above line will cause a redirect to pages.PREQUAL_DEFAULT. However, the
      // below history.replace() triggers an immediate redirection, making the UI transformation much smoother
      history.replace(getPathFromPage(pages.PREQUAL_DEFAULT));
    } else {
      setFormError(i18n.thisField);
    }
  };

  return (
    <ModalPage
      pageName={i18n.pageName}
      numIndicators={totalPages}
      currentIndicatorIndex={flowIndex}
      content={{
        title: `Are you ${applicantName}?`,
      }}
    >
      <form noValidate onSubmit={handleSubmit}>
        <Checkbox
          label="Yes"
          value="yes"
          onChange={handleChange}
          checked={answer === 'yes'}
          focused
        />
        <Checkbox label="No" value="no" onChange={handleChange} checked={answer === 'no'} />
        <Button block type="submit">
          {i18n.continue}
        </Button>
      </form>
      <HelpTextAnimated
        show={!!formError}
        styleMarginPosition={DirectionAndPlacement.Top}
        styleType={Style.Error}
      >
        {formError}
      </HelpTextAnimated>
    </ModalPage>
  );
};

export default ConfirmName;
