import React, { useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import { Transition } from 'react-transition-group';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import * as styles from './styles';

export interface FollowSectionProps extends Pick<styles.FollowSectionStyleProps, 'placement'> {
  children: React.ReactNode;
  show: boolean;
  adjustBody?: boolean;
}

const FollowSection = ({
  children,
  show,
  placement = DirectionAndPlacement.Top,
  adjustBody = false, // will adjust the body padding to prevent FollowSection from overlapping other elements
}: FollowSectionProps) => {
  const followRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>();
  const throttledResize = throttle(
    () => setHeight(followRef.current?.getBoundingClientRect().height),
    100
  );

  useEffect(() => {
    throttledResize();
    window.addEventListener('resize', throttledResize);
    return () => {
      window.removeEventListener('resize', throttledResize);
    };
  }, [throttledResize]);

  return (
    <>
      <styles.FollowBodyStyle
        show={adjustBody && show}
        placement={placement}
        padding={`${height}px`}
      />
      <Transition in={show} timeout={200} unmountOnExit>
        {(status) => (
          <styles.FollowSectionStyle animationStatus={status} placement={placement} ref={followRef}>
            {children}
          </styles.FollowSectionStyle>
        )}
      </Transition>
    </>
  );
};

export default FollowSection;
