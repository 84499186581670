import { useDispatch, useStore } from 'react-redux';
import { generateUrlFromPage } from 'containers/helpers';
import { nextPageFromResponse } from 'containers/prequal/pages/utils';
import { useHistory } from 'containers/routerHelpers';
import bugsnagClient from 'lib/bugsnagClient';
import { usePostHogEvents } from 'lib/posthogEvents';
import {
  PrequalApplicationRequest,
  PrequalApplicationResponse,
  usePostApplicationMutation,
} from 'services/api/prequalApi';
import { RootState } from 'store';
import { clearApplication } from 'store/actions';
import { Products } from 'store/constants';
import { setIneligibility } from 'store/prequalApplication';
import { APPLICATION_VALIDATION_CODES, PQ_APPLICATION_STATUS } from 'types';

export const usePrequalApplication = () => {
  const [postApplicationMutation] = usePostApplicationMutation();
  const store = useStore();
  const history = useHistory();
  const dispatch = useDispatch();
  const posthogEvents = usePostHogEvents();

  // Gather data from various parts of the store and submit a Prequal application
  async function postPrequalApplication() {
    const { prequalApplication, applicant, property, general } = store.getState() as RootState;

    try {
      const applicationData: PrequalApplicationRequest = {
        applicant,
        property,
        utmParameters: general.utmParameters,
        features: general.featureFlags,
        offerCode: prequalApplication?.directMailData?.offerCode as string,
      };

      const result = await postApplicationMutation(applicationData).unwrap();
      return result;
    } catch (e: unknown) {
      const possibleInputError = e as InputError;

      const errors = possibleInputError.data?.errors || [];
      const errorCodes = errors.map(({ code }) => code);
      if (errorCodes.includes(APPLICATION_VALIDATION_CODES.INVALID_FIRST_NAME)) {
        throw new Error(APPLICATION_VALIDATION_CODES.INVALID_FIRST_NAME);
      }
      if (errorCodes.includes(APPLICATION_VALIDATION_CODES.INVALID_LAST_NAME)) {
        throw new Error(APPLICATION_VALIDATION_CODES.INVALID_LAST_NAME);
      }
      if (errorCodes.includes(APPLICATION_VALIDATION_CODES.INVALID_PHONE_NUMBER)) {
        throw new Error(APPLICATION_VALIDATION_CODES.INVALID_PHONE_NUMBER);
      }
      if (errorCodes.includes(APPLICATION_VALIDATION_CODES.INVALID_EMAIL)) {
        throw new Error(APPLICATION_VALIDATION_CODES.INVALID_EMAIL);
      }
      if (errorCodes.includes(APPLICATION_VALIDATION_CODES.INVALID_ADDRESS)) {
        throw new Error(APPLICATION_VALIDATION_CODES.INVALID_ADDRESS);
      }
      throw e;
    }
  }

  // Review an application response (presumably from postPrequalApplication() above)
  // and route user to the appropriate page
  function processApplicationResponse(response: PrequalApplicationResponse) {
    if (!response?.products?.hei) {
      const errorMessage = `No HEI data found in response: ${JSON.stringify(response)}`;
      bugsnagClient.notify(errorMessage);
      throw new Error(errorMessage);
    }

    const { status, links, problems } = response.products.hei;
    const product = Products.HEI;

    posthogEvents.captureEstimateCreated({
      estimate: response.products.hei,
      applicant: response.applicant,
      status,
    });

    if (status === PQ_APPLICATION_STATUS.ELIGIBLE) {
      const url = new URL(links.html!);
      history.push(`${url.pathname}${url.search}`);
      dispatch(clearApplication({ product }));
    } else {
      if (status === PQ_APPLICATION_STATUS.INELIGIBLE) {
        dispatch(
          setIneligibility({
            product,
            status,
            problems,
          })
        );
      }
      history.push(generateUrlFromPage(nextPageFromResponse(product, response), { product }));
    }
  }

  return { postPrequalApplication, processApplicationResponse };
};

interface InputError {
  data?: {
    response: string;
    errors: Array<{ code: string; message: string }>;
  };
}

/*
Sample invalid input response:

400 Bad Request
  {
      "response": "validationErrors",
      "errors": [
          {
              "code": "invalid_phone_number",
              "message": "Invalid phone number"
          },
          {
              "code": "invalid_email",
              "message": "Invalid email"
          }
      ]
  }
*/
