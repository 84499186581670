import React from 'react';
import { ButtonClickFunc, Loader, Size, templatedString } from '@pointdotcom/pds';
import { TaskFileUpload } from 'containers/hooks/useTaskFileUpload';
import { TaskFile } from 'services/apiTypes/taskTypes';
import i18n from './i18n';
import { TrashCanIcon } from './icons';
import * as styles from './styles';

interface DeleteFileButtonButtonProps {
  onClick: ButtonClickFunc;
  fileName: string;
  hasError?: boolean;
  deleting?: boolean;
}

export function DeleteFileButton({
  onClick,
  fileName,
  hasError = false,
  deleting = false,
}: DeleteFileButtonButtonProps) {
  return (
    <styles.IconButtonStyle
      onClick={onClick}
      aria-label={templatedString({
        values: { fileName },
        template: i18n.deleteTheFileNamed,
      })}
      isLoading={deleting}
    >
      <TrashCanIcon hasError={hasError} deleting={deleting} />
      {deleting && <Loader styleSize={Size.Small} />}
    </styles.IconButtonStyle>
  );
}

export interface UploadedFileItemProps {
  taskId: string;
  taskFile?: TaskFile;
  upload?: TaskFileUpload;
  isDeleting?: boolean;
  deleteFileError?: string | null;
  onFileClick?: (fileName: string) => void;
  onDeleteFile: ButtonClickFunc;
}
