import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfig } from 'axios';
import { z } from 'zod';
import bugsnagClient from 'lib/bugsnagClient';
import { getHomeownerHost } from 'lib/hosts';
import {
  ContractExitDetails,
  ContractProjections,
  contractExitDetailsSchema,
  contractProjectionsSchema,
} from 'services/apiTypes/contractTypes';
import {
  ApplicationApiResponse,
  ApplicationStatusResponse,
  GetApplicationResponse,
  HosDashboardResponse,
  HosPresignedPost,
  InProgressHeiApplication,
  PostApplicationRequest,
  PostApplicationSubmitRequest,
  applicationApiResponseSchema,
  applicationStatusResponseSchema,
  hosDashboardResponseSchema,
  hosPresignedPostSchema,
} from 'services/apiTypes/homeownerTypes';
import {
  AnswersApiResponse,
  ApplicantWithoutResult,
  PostAnswers,
  QuestionsApiResponse,
  answersApiResponseSchema,
  applicantWithoutResultSchema,
  questionsApiResponseSchema,
} from 'services/apiTypes/quizTypes';
import {
  Task,
  TaskFile,
  TaskFileUploadSuccessResponse,
  TaskHelpRequest,
  TaskStatus,
  TaskSubmitRequest,
  TaskType,
  taskFileUploadSuccessResponseSchema,
  taskSchema,
} from 'services/apiTypes/taskTypes';
import {
  PostStatistics,
  VideoApiResponse,
  videoApiResponseSchema,
} from 'services/apiTypes/videoTypes';
import { axiosBaseQuery, parseResponse } from './baseQuery';

export const applicationStatusTag = 'application-status';
export const dashboardTag = 'homeowner-dashboard';

export const homeownerApi = createApi({
  reducerPath: 'homeownerApi',
  tagTypes: [applicationStatusTag, dashboardTag, 'homeowner-followup', 'homeowner-quiz'],
  baseQuery: axiosBaseQuery({
    baseUrl: getHomeownerHost(),
  }),
  endpoints: (builder) => ({
    /*
     * Dashboard endpoints
     */
    dashboardLogin: builder.mutation<void, { emailAddress: string }>({
      query: ({ emailAddress }) => ({
        url: `/dashboard/login`,
        method: 'POST',
        data: { emailAddress },
      }),
    }),

    dashboard: builder.query<
      HosDashboardResponse,
      { personId: null | string; personType: null | string }
    >({
      query: ({ personId, personType }) => ({
        url: `/dashboard`,
        params: { person_id: personId, person_type: personType },
      }),
      transformResponse: (response: unknown) =>
        parseResponse(response, hosDashboardResponseSchema, 'dashboard'),
      providesTags: [dashboardTag],
    }),

    dashboardLogout: builder.mutation({
      query: () => ({
        url: `/dashboard/logout`,
        method: 'POST',
      }),
      invalidatesTags: [dashboardTag],
    }),

    /*
     * HEI Product quiz endpoints
     */
    getQuizQuestions: builder.query<QuestionsApiResponse, void>({
      query: () => {
        return {
          url: `/quiz/questions`,
        };
      },
      transformResponse: (response: unknown) =>
        parseResponse(response, questionsApiResponseSchema, 'getQuizQuestions'),
    }),

    getQuizResults: builder.query<AnswersApiResponse | ApplicantWithoutResult, void>({
      query: () => {
        return {
          url: `/quiz/answers`,
        };
      },
      transformResponse: (response: unknown) => {
        const unionSchema = z.discriminatedUnion('applicantStatus', [
          applicantWithoutResultSchema,
          answersApiResponseSchema,
        ]);

        return parseResponse(response, unionSchema, 'getQuizResults');
      },
      providesTags: ['homeowner-quiz'],
    }),

    postQuizAnswers: builder.mutation<void, PostAnswers>({
      query: (quizData) => {
        return {
          url: `/quiz/answers`,
          method: 'POST',
          data: quizData,
        };
      },
      invalidatesTags: ['homeowner-quiz', dashboardTag],
    }),

    getVideo: builder.query<VideoApiResponse, string>({
      query: (label) => ({
        url: `/video/${label}`,
      }),
      transformResponse: (response: unknown) =>
        parseResponse(response, videoApiResponseSchema, 'getVideo'),
    }),

    postVideoProgress: builder.mutation<void, PostStatistics & { label: string }>({
      query: ({ label, ranges, tags }) => ({
        url: `/video/${label}/statistics`,
        method: 'POST',
        data: { ranges, tags },
      }),
    }),

    /*
     * HEI Application endpoints
     */
    getApplication: builder.query<GetApplicationResponse, string>({
      query: (estimateKey) => ({
        url: `/application/${estimateKey}`,
      }),
      // Don't parse the response here because it is expected to be an incomplete application and will often fail schema validation
    }),

    createApplication: builder.mutation<ApplicationApiResponse, PostApplicationRequest>({
      query: (application) => ({
        url: `/application`,
        method: 'POST',
        data: application,
      }),
      transformResponse: (response: unknown) =>
        parseResponse(response, applicationApiResponseSchema, 'createApplication'),
    }),

    patchApplication: builder.mutation<void, InProgressHeiApplication>({
      // Note that we do not invalidate the application data here because we don't want to
      // refetch on every update. The server is a backup of the data in the browser, so we
      // just fetch once on initial application page load and let the client handle state from there.
      query: (application) => {
        return {
          url: `/application`,
          method: 'PATCH',
          data: application,
        };
      },
    }),

    submitApplication: builder.mutation<ApplicationApiResponse, PostApplicationSubmitRequest>({
      query: (application) => ({
        url: `/application/submit`,
        method: 'POST',
        data: application,
      }),
      transformResponse: (response: unknown) =>
        parseResponse(response, applicationApiResponseSchema, 'submitApplication'),
      invalidatesTags: [dashboardTag],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const submittedAt = new Date().toJSON();
          await queryFulfilled;
          // "Pessimistically" mark application task as submitted:
          dispatch(
            homeownerApi.util.updateQueryData(
              'dashboard',
              { personId: null, personType: null },
              (draft) => {
                if (draft.success === true && draft.tasks != null) {
                  for (const draftTask of draft.tasks) {
                    if (draftTask.type === TaskType.Application) {
                      draftTask.status = TaskStatus.Submitted;
                      draftTask.submittedAt = submittedAt;
                    }
                  }
                }
              }
            )
          );
        } catch (error: TSFixMe) {
          bugsnagClient.notify(error);
        }
      },
    }),

    createDocket: builder.mutation<void, string>({
      query: (estimateKey) => ({
        url: `/application/estimates/${estimateKey}`,
        method: 'POST',
      }),
      invalidatesTags: [dashboardTag],
    }),

    /*
     * Task endpoints
     */
    getTask: builder.query<Task, { id: string }>({
      query: ({ id }) => ({
        url: `/task/${id}`,
      }),
      providesTags: (result, error, arg) => {
        return [{ type: 'homeowner-followup', id: arg?.id }];
      },
      transformResponse: (response: unknown) => parseResponse(response, taskSchema, 'getTask'),
    }),

    // Note: this is just a notifcation to the server that the task has been opened, no need to invalidate the task
    taskOpened: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/task/${id}/open`,
        method: 'POST',
      }),
    }),

    postTask: builder.mutation<void, { id: string; data: TaskSubmitRequest }>({
      query: ({ id, data }) => ({
        url: `/task/${id}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, arg) => {
        return [dashboardTag, { type: 'homeowner-followup', id: arg?.id }];
      },
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        try {
          const submittedAt = new Date().toJSON();
          await queryFulfilled;
          // "Pessimistically" mark task as submitted:
          dispatch(
            homeownerApi.util.updateQueryData(
              'dashboard',
              { personId: null, personType: null },
              (draft) => {
                if (draft.success === true && draft.tasks != null) {
                  for (const draftTask of draft.tasks) {
                    if (draftTask.id === id) {
                      draftTask.status = TaskStatus.Submitted;
                      draftTask.submittedAt = submittedAt;
                    }
                  }
                }
              }
            )
          );
          dispatch(
            homeownerApi.util.updateQueryData('getTask', { id }, (draft) => {
              draft.status = TaskStatus.Submitted;
              draft.submittedAt = submittedAt;
            })
          );
        } catch (error: TSFixMe) {
          bugsnagClient.notify(error);
        }
      },
    }),

    postHelpRequest: builder.mutation<void, { id: string; data: TaskHelpRequest }>({
      query: ({ id, data }) => ({
        url: `/task/${id}/help`,
        method: 'POST',
        data,
      }),
    }),

    getPresignedUrl: builder.mutation<HosPresignedPost, void>({
      query: () => ({
        url: `/document/presignedUrl`,
        method: 'POST',
      }),
      transformResponse: (response: unknown) =>
        parseResponse(response, hosPresignedPostSchema, 'getPresignedUrl'),
    }),

    uploadTaskFile: builder.mutation<
      TaskFileUploadSuccessResponse,
      {
        taskId: string;
        file: File;
        documentCategory?: string;
        onUploadProgress?: AxiosRequestConfig['onUploadProgress'];
      }
    >({
      query: ({ taskId, file, documentCategory, onUploadProgress }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('command', 'upload_file');
        if (documentCategory != null) {
          formData.append('documentCategory', documentCategory);
        }

        return {
          url: `/task/${taskId}/upload`,
          method: 'POST',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress,
        };
      },
      transformResponse: (response: unknown) =>
        parseResponse(response, taskFileUploadSuccessResponseSchema, 'uploadTaskFile'),
      onQueryStarted: async ({ taskId, documentCategory }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          // "Pessimistically" update file list on relevant task:
          dispatch(
            homeownerApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
              let { files } = draft;
              if (draft.type === TaskType.DocumentUpload) {
                const section = draft.detail.sections.find(
                  (s) => s.documentCategory === documentCategory
                );
                if (section != null) {
                  ({ files } = section);
                }
              }
              if (!files.find((taskFile) => taskFile.fileId === data.fileId)) {
                files.push(data);
              }
            })
          );
        } catch (error: TSFixMe) {
          bugsnagClient.notify(error);
        }
      },
    }),

    deleteTaskFile: builder.mutation<void, { taskId: string; fileId: string }>({
      query: ({ taskId, fileId }) => ({
        url: `/task/${taskId}/file/${fileId}`,
        method: 'DELETE',
      }),
      onQueryStarted: async ({ taskId, fileId }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          // "Pessimistically" update file list on relevant task:
          dispatch(
            homeownerApi.util.updateQueryData('getTask', { id: taskId }, (draft) => {
              let fileLists: Array<{ files: TaskFile[] }> = [draft];
              if (draft.type === TaskType.DocumentUpload) {
                fileLists = draft.detail.sections;
              }
              for (const fileList of fileLists) {
                const index = fileList.files.findIndex((taskFile) => taskFile.fileId === fileId);
                if (index >= 0) {
                  fileList.files.splice(index, 1);
                }
              }
            })
          );
        } catch (error: TSFixMe) {
          bugsnagClient.notify(error);
        }
      },
    }),

    getContractExit: builder.query<
      ContractExitDetails,
      { homeValue: number | null; payoffDate: string | null }
    >({
      query: ({ homeValue, payoffDate }) => ({
        url: `/contract/exit`,
        params: { homeValue, payoffDate },
      }),
      transformResponse: (response: unknown) =>
        parseResponse(response, contractExitDetailsSchema, 'getContractExit'),
    }),

    getContractProjections: builder.query<ContractProjections, void>({
      query: () => ({
        url: `/contract/projections`,
      }),
      transformResponse: (response: unknown) =>
        parseResponse(response, contractProjectionsSchema, 'getContractProjections'),
    }),

    getApplicationStatus: builder.query<ApplicationStatusResponse, { estimateKey: string }>({
      query: ({ estimateKey }) => ({
        url: `/application/${encodeURIComponent(estimateKey)}/status`,
      }),
      transformResponse: (response: unknown) =>
        parseResponse(response, applicationStatusResponseSchema, 'getApplicationStatus'),
      providesTags: [applicationStatusTag],
    }),
  }),
});

export const {
  useDashboardLoginMutation,
  useDashboardQuery,
  useDashboardLogoutMutation,
  useGetQuizQuestionsQuery,
  useGetQuizResultsQuery,
  usePostQuizAnswersMutation,
  useGetVideoQuery,
  usePostVideoProgressMutation,
  useGetApplicationQuery,
  useLazyDashboardQuery,
  useLazyGetApplicationQuery,
  useCreateApplicationMutation,
  usePatchApplicationMutation,
  useSubmitApplicationMutation,
  useCreateDocketMutation,
  useGetTaskQuery,
  usePostTaskMutation,
  useTaskOpenedMutation,
  usePostHelpRequestMutation,
  useGetPresignedUrlMutation,
  useUploadTaskFileMutation,
  useDeleteTaskFileMutation,
  useGetContractExitQuery,
  useLazyGetContractExitQuery,
  useGetContractProjectionsQuery,
  util: homeownerApiUtil,
} = homeownerApi;
