import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { recordFeatureFlag } from 'store/general';
import { posthogFeatureFlagDefinitions } from './featureFlagDefinitions';
import { FeatureFlag, FeatureFlagValue, FeatureFlagsContext } from './featureFlagObjects';
import { getFeatureFlagOverride } from './featureFlagOverrides';
import { PostHogClientAndFeatureFlagsProvider } from './posthogFeatureFlags';

export { FeatureFlag };

export function getPosthogFeatureFlagKey(flag: FeatureFlag): string | undefined {
  return posthogFeatureFlagDefinitions[flag]?.key;
}

export function useFeatureFlagsReady(): boolean {
  const featureFlags = useContext(FeatureFlagsContext);

  return featureFlags != null;
}

/**
 * Returns:
 *  - `undefined` if this feature flag is not enabled, or if feature flags have not yet loaded
 *  - Otherwise the feature flag value
 */
export function useFeatureFlag<TFlag extends FeatureFlag>(
  flag: TFlag,
  { skip = false }: { skip?: boolean } = {}
): null | undefined | FeatureFlagValue<TFlag> {
  const featureFlags = useContext(FeatureFlagsContext);
  const dispatch = useDispatch();

  const flagValue = useMemo(
    () => (skip ? null : (getFeatureFlagOverride(flag) ?? featureFlags?.getValue(flag))),
    [featureFlags, flag, skip]
  );

  // Add feature flag to the store to record which features were encountered in the session
  useEffect(() => {
    if (flagValue != null) {
      dispatch(recordFeatureFlag({ flag: getPosthogFeatureFlagKey(flag), value: flagValue }));
      // Cannot dispatch directly in the hook's main body because it will change component state during render
      // https://github.com/facebook/react/issues/18178#issuecomment-600134995
    }
  }, [dispatch, flag, flagValue]);

  return flagValue;
}

interface FeatureFlagsProviderProps {
  children: React.ReactNode;
  PostHogProvider?: React.ComponentProps<
    typeof PostHogClientAndFeatureFlagsProvider
  >['PostHogProvider'];
}

export function FeatureFlagsProvider({
  children,
  PostHogProvider,
}: FeatureFlagsProviderProps): JSX.Element {
  return (
    <PostHogClientAndFeatureFlagsProvider PostHogProvider={PostHogProvider}>
      {children}
    </PostHogClientAndFeatureFlagsProvider>
  );
}
