import React, { useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { createGlobalStyle } from 'styled-components';
import {
  CloseButton,
  Container,
  DirectionAndPlacement,
  Header,
  Icon,
  IconName,
  Key,
  LinkButton,
  ModalStyles,
  Size,
  eventHasKey,
  focusableSelector,
  handleModalFocus,
  mixins,
} from '@pointdotcom/pds';
import { NavItem, NavProps, getNavItemProp } from 'components/MainHeader/nav';
import { getMainHeaderMobileNavMenuOpen, setMainHeaderMobileNavMenuOpen } from 'store/general';
import { MainPageStyle } from 'styles/';
import {
  PopupCloseSectionStyle,
  PopupMenuContentStyle,
  PopupMenuNavItemStyle,
  PopupMenuNavStyle,
  PopupMenuStyle,
} from './styles';

const PopupMenuOpenGlobalStyle = createGlobalStyle`
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    ${MainPageStyle} {
      transform: scale(0.9);
      opacity: 0;
    }
  }
`;

interface PopupMenuProps {
  clickedElement?: React.RefObject<null | HTMLButtonElement>;
  navItems: ReadonlyArray<NavItem>;
  navProps?: NavProps;
}

export default function PopupMenu({ clickedElement, navItems, navProps = {} }: PopupMenuProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const dispatch = useDispatch();
  const menuOpen = useSelector(getMainHeaderMobileNavMenuOpen);
  const popupMenuRef = useRef<null | HTMLDivElement>(null);
  const timeout = useRef<undefined | NodeJS.Timeout>();
  const lastActiveElement = useRef<null | HTMLElement>(null);
  const getHref = getNavItemProp('href', navProps);
  const getText = getNavItemProp('text', navProps);
  const { hidePopupTitle = false } = navProps;

  const handleResize = () => {
    setForceUpdate(!forceUpdate); // trigger the recalc of the menu position
  };

  const throttledResize = throttle(handleResize, 500);

  const handleFocus = (e?: KeyboardEvent) => {
    const thisElm = popupMenuRef.current;
    if (!thisElm) {
      return;
    }
    const elementContext = thisElm.querySelector('.PopupMenuContentStyle');
    if (!(elementContext instanceof HTMLElement)) {
      return;
    }
    const itemElements = elementContext.querySelectorAll(focusableSelector);
    const controlElements = thisElm.querySelectorAll('.CloseButtonStyle');

    handleModalFocus(e as TSFixMe, {
      elementContext,
      focusableElements: [
        ...(itemElements as NodeListOf<HTMLElement>),
        ...(controlElements as NodeListOf<HTMLElement>),
      ],
    });
  };

  const handleCloseMenu = useCallback(() => {
    dispatch(setMainHeaderMobileNavMenuOpen(false));
  }, [dispatch]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!e) {
        return;
      }
      if (eventHasKey(e, Key.Esc)) {
        handleCloseMenu();
      }

      if (eventHasKey(e, Key.Tab)) {
        handleFocus(e);
      }
    },
    [handleCloseMenu]
  );

  const resetFocus = () => {
    if (lastActiveElement.current) {
      lastActiveElement.current.focus(); // return focus to the original object that triggered the modal
    }
  };

  useEffect(() => {
    // The lint rules asked to use this pattern for references to ref in the returning (unmount) function below
    const popupMenuRefPlaceholder = popupMenuRef.current;
    const currentTimeout = timeout.current;
    window.addEventListener('resize', throttledResize);
    return () => {
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }
      window.removeEventListener('resize', throttledResize);
      if (popupMenuRefPlaceholder) {
        popupMenuRefPlaceholder.removeEventListener('keydown', handleKeyDown, false);
      }
    };
  }, [handleKeyDown, throttledResize]);

  const handleOnEntered = () => {
    lastActiveElement.current = document.activeElement as null | HTMLElement;
    if (popupMenuRef.current) {
      popupMenuRef.current.addEventListener('keydown', handleKeyDown, false);
      handleFocus();
    } else {
      setForceUpdate(!forceUpdate);
    }
  };

  const getClickedElementPositionX = () => {
    if (!clickedElement?.current || !popupMenuRef?.current) {
      return null; // NOTE: returning 0 here instead of null causes a glitch in the animation
    }

    return (
      clickedElement.current.getBoundingClientRect().x -
      popupMenuRef.current.offsetWidth +
      clickedElement.current.offsetWidth
    );
  };

  const getClickedElementIsDisplayed = () => {
    // popup is controlled without an external element triggering it
    if (!clickedElement) {
      return true;
    }

    // popup is controlled with an external element triggering it
    if (clickedElement?.current) {
      return window.getComputedStyle(clickedElement.current).display !== 'none';
    }

    return false;
  };

  const handleClick = (item: NavItem) => (e: React.UIEvent) => {
    e.preventDefault();
    const href = getHref(item);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (item.action) {
      item.action();
    } else if (href) {
      handleCloseMenu();
      const currentTimeout = setTimeout(() => {
        navigate(href);
      }, 320); // Magic number here to acount for mobile menu animation
      timeout.current = currentTimeout;
    }
  };

  return (
    <Transition
      appear
      in={menuOpen && getClickedElementIsDisplayed()}
      mountOnEnter
      unmountOnExit
      className="PopupMenu"
      timeout={0}
      onEntered={handleOnEntered}
      onExited={resetFocus}
    >
      {(status) => (
        <>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          {status === 'entered' && <mixins.BodyForFixedStyle onlyMobileFixed />}
          {(status === 'entering' || status === 'entered') && <PopupMenuOpenGlobalStyle />}
          <Portal>
            <ModalStyles.ModalBGStyle
              animationStatus={status}
              zIndex={9998}
              onClick={handleCloseMenu}
            />
          </Portal>
          <Portal>
            <PopupMenuStyle
              animationStatus={status}
              style={{ left: `${getClickedElementPositionX()}px` }}
              ref={popupMenuRef}
              role="dialog"
            >
              <PopupCloseSectionStyle>
                <CloseButton
                  inverted
                  onClick={() => {
                    if (status === 'entered') {
                      handleCloseMenu();
                    }
                  }}
                />
              </PopupCloseSectionStyle>
              <PopupMenuContentStyle>
                <Container>
                  {!hidePopupTitle && (
                    <Header
                      inverted
                      styleSize={Size.Splash}
                      styleAlignMobile={DirectionAndPlacement.Left}
                    >
                      Jump to
                    </Header>
                  )}
                  <PopupMenuNavStyle>
                    {navItems.map((item) => {
                      const href = getHref(item);
                      const text = getText(item);
                      return (
                        <PopupMenuNavItemStyle
                          key={text}
                          current={location.pathname === href}
                          data-ga-tracking-id={
                            text != null ? `Nav${text.replace(/\s/g, '')}` : undefined
                          }
                        >
                          <LinkButton onClick={handleClick(item)}>
                            {text}
                            {href != null && (
                              <>
                                <Icon name={IconName.ChevronRight} styleWidth={20} />{' '}
                              </>
                            )}
                          </LinkButton>
                        </PopupMenuNavItemStyle>
                      );
                    })}
                  </PopupMenuNavStyle>
                </Container>
              </PopupMenuContentStyle>
            </PopupMenuStyle>
          </Portal>
        </>
      )}
    </Transition>
  );
}
