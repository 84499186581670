export default {
  pageName: 'Mortgage Balance',
  continue: 'Continue',
  whatsTheMortgage: 'What’s the mortgage balance on your home?',
  itsOkay: 'It’s ok to estimate. If you don’t have a mortgage, you can put $0.',
  yourMortgage: 'Your mortgage balance',
  whatIfIHave: 'What if I have a second mortgage or HELOC?',
  youMayStill:
    'You may still qualify for a Point Home Equity Investment, though you will be required to own more equity in your home.',
  // FeatureFlag.PrequalContact
  whyDoesPoint: 'Why does Point need to know my mortgage balance?',
  pointNeedsTo:
    'Point needs to know your mortgage balance to accurately assess your financial situation and determine the amount of equity you have in your home. This information helps us evaluate the Home Equity Investment (HEI) amount you may qualify for. ',
};
