import React from 'react';
import { useDispatch } from 'react-redux';
import { CheckBoxOnChangeEvent, Checkbox, HelpText, Style } from '@pointdotcom/pds';
import { HeiApplicationConsent } from 'services/apiTypes/homeownerTypes';
import { setData } from 'store/productApplication';
import { FieldProps, FormDescriptionType } from '../constants';
import * as styles from '../styles';

function extractStringValuesFromJSX(element: React.ReactElement | string): string {
  if (typeof element === 'string') {
    return element;
  }

  const stringValues: string[] = [];

  React.Children.forEach(element.props.children, (child) => {
    if (typeof child === 'string') {
      stringValues.push(child);
    }
  });

  return stringValues.join(' ');
}

const CheckboxStackField = ({
  description,
  keyValues,
  asArray = false,
  error,
  helptext,
  styleSize,
  path: pathFromProps = '',
  value: valueFromProps,
  type = 'checkbox',
  onFocus,
  handleChange: handleChangeFromProps,
}: Partial<FieldProps> & {
  type?: 'checkbox' | 'radio';
  keyValues: Record<string, FormDescriptionType>;
  description?: FormDescriptionType;
  asArray?: boolean;
}) => {
  const dispatch = useDispatch();
  const getPathKey = (fromFullPath: string) =>
    fromFullPath.replace(new RegExp(`^${pathFromProps}.`), '');
  const existingValue = valueFromProps;
  let arr: Array<string> = [];
  if (Array.isArray(existingValue)) {
    arr = [...(existingValue as Array<string>)];
  }

  const handleChange =
    (path: string): CheckBoxOnChangeEvent =>
    (e, { value }) => {
      if (type === 'radio') {
        handleChangeFromProps?.(pathFromProps)(e, { value });
      } else if (asArray) {
        if (!arr.includes(path)) {
          arr.push(path);
        } else {
          arr = arr.filter((v) => v !== path);
        }
        dispatch(setData({ dataPath: pathFromProps, value: arr }));
      } else {
        const oldValue = existingValue?.[getPathKey(path) as keyof typeof existingValue];
        handleChangeFromProps?.(path)(e, { value: oldValue ? '' : value });
      }
    };
  return (
    <styles.CheckboxStackFieldStyle styleSize={styleSize}>
      {description && <styles.ParagraphBlockStyle>{description}</styles.ParagraphBlockStyle>}
      <styles.ListOfElementsStyle>
        {Object.keys(keyValues).map((key) => {
          const value = keyValues[key as keyof typeof keyValues] as string | React.ReactElement;
          let stringValue = extractStringValuesFromJSX(value);
          const path = `${pathFromProps}.${key}`;

          let checked = false;
          if (asArray && Array.isArray(valueFromProps)) {
            checked = (valueFromProps as Array<string>).includes(stringValue);
          }
          if (typeof valueFromProps === 'object') {
            checked = Object.values(valueFromProps as HeiApplicationConsent).includes(stringValue);
          }
          if (type === 'radio') {
            stringValue = key;
            checked = valueFromProps === key;
          }

          return (
            <Checkbox
              key={key}
              type={type}
              label={value}
              value={stringValue}
              onChange={handleChange(asArray ? stringValue : path)}
              checked={checked}
              noMargin
              styleSize={styleSize}
              error={error}
              onFocus={onFocus}
            />
          );
        })}
      </styles.ListOfElementsStyle>
      {helptext && <HelpText styleType={error ? Style.Error : Style.Default}>{helptext}</HelpText>}
    </styles.CheckboxStackFieldStyle>
  );
};

export default CheckboxStackField;
