import React, { useEffect } from 'react';
import { Location } from 'history';
import nullthrows from 'nullthrows';
import { useDispatch, useSelector } from 'react-redux';
import { DirectionAndPlacement } from '@pointdotcom/pds';
import {
  getBannerProps as getResumeApplicationBannerProps,
  getText as getResumeApplicationBannerText,
} from 'components/Banners/ResumeApplicationBanner';
import ContactModal from 'components/EmailOfferContactModal';
import FullScreenLoading from 'components/FullScreenLoading';
import { BannerMessageType, GenericBannerProps } from 'components/GenericMessageBanner';
import { HeaderType } from 'components/MainHeader';
import {
  NavItem,
  NavProps,
  navItemsHeiBasicEducation,
  navItemsHeiEnhancedEducation,
} from 'components/MainHeader/nav';
import { YCBMCal } from 'components/ScheduleCalendar/constants';
import { SubHeaderContentType, SubHeaderProps } from 'components/SubHeader';
// Pages
import AboutPage from 'containers/AboutPage';
import ErrorPage, { ErrorType } from 'containers/ErrorPage';
import PricingPage from 'containers/PricingPage';
import ScheduleCompletePage from 'containers/ScheduleCompletePage';
import HEIApplicationPage from 'containers/hei/ApplicationPage';
import { Page, generateUrl, pageRouteMap } from 'containers/helpers';
import { useCalendar } from 'containers/prequal/hooks/useCalendar';
import {
  HistoryNavigation,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from 'containers/routerHelpers';
import { logInfo } from 'lib/logger';
import OfferEstimateModel from 'models/OfferEstimateModel';
import { useGetVisitorDataQuery, useLazyGetHeiApplicationUrlQuery } from 'services/api/prequalApi';
import { RootState } from 'store';
import { getApplicantModel } from 'store/applicant';
import { Products } from 'store/constants';
import {
  PricingRangeType,
  getEstimatesAreLoading,
  getEstimatesHaveError,
  getHEIEstimateModel,
  getPriceRangeModel,
} from 'store/estimates';
import { getEstimate, getPricingRange } from 'store/thunks/estimates';

interface EstimatePageComponentProps {
  page: Page;
  history: HistoryNavigation;
  location: Location;
  navProps: NavProps;
  bannerProps: GenericBannerProps;
  navItems: NavItem[];
  estimateKey: string | undefined;
  estimate: OfferEstimateModel;
  pricingRange: PricingRangeType | null;
  calendar: YCBMCal;
  subHeaderProps: SubHeaderProps;
  headerType: HeaderType;
}

type EstimatesControllerPage =
  | Page.HEI_ABOUT
  | Page.HEI_PRICING
  | Page.SCHEDULE_COMPLETE
  | Page.HEI_APPLICATION;

const pageContentMap: Record<
  EstimatesControllerPage,
  React.ComponentType<EstimatePageComponentProps>
> = {
  // Estimates
  [Page.HEI_ABOUT]: AboutPage as TSFixMe,
  [Page.HEI_PRICING]: PricingPage,
  [Page.SCHEDULE_COMPLETE]: ScheduleCompletePage as TSFixMe,
  [Page.HEI_APPLICATION]: HEIApplicationPage,
};

interface EstimatesControllerProps {
  page?: EstimatesControllerPage;
}

export default function EstimatesController({
  page,
}: EstimatesControllerProps): null | JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const { estimateKey } = useParams();
  const applicant = useSelector(getApplicantModel);
  const estimate = useSelector(getHEIEstimateModel);

  const pricingRange = useSelector(getPriceRangeModel);
  const estimateLoading = useSelector(getEstimatesAreLoading);
  const pricingEstimateLoadNotStarted = useSelector((state: RootState) => {
    return !state.estimates.estimateLoadState;
  });
  const pricingRangeEstimateLoadNotStarted = useSelector(
    (state: RootState) => !state.estimates[Products.HEI].pricingRangeLoadState
  );
  const estimateHasError = useSelector(getEstimatesHaveError);
  const { data: visitor, isLoading: visitorDataLoading } = useGetVisitorDataQuery();
  const hasNewOffer =
    (pricingRange as TSFixMe)?.mostRecentEstimateKey &&
    (pricingRange as TSFixMe)?.mostRecentEstimateKey !== estimateKey;
  // ^^ Note: It does not seem possible for pricingRange to ever have a mostRecentEstimateKey property
  const PageComponent = page ? pageContentMap[page] : undefined;
  const [getHeiApplicationUrl] = useLazyGetHeiApplicationUrlQuery();

  // Basic education flow is the default

  let navItems = navItemsHeiBasicEducation;

  let defaultPage = Page.HEI_OFFER;
  let bannerProps = {};

  // Get the priceEstimate
  useEffect(() => {
    if (!estimateKey || !pricingEstimateLoadNotStarted || estimate) {
      return;
    }
    dispatch(getEstimate({ estimateKey }));
  }, [estimateKey, estimate, pricingEstimateLoadNotStarted, dispatch]);

  // Get the priceRangeEstimate
  useEffect(() => {
    if (!estimateKey || !pricingRangeEstimateLoadNotStarted || pricingRange) {
      return;
    }
    dispatch(getPricingRange({ estimateKey }));
  }, [estimateKey, pricingRange, pricingRangeEstimateLoadNotStarted, dispatch]);

  const { calendar } = useCalendar({
    forStateAbbr: estimate?.property?.address?.state,
    forPartners: estimate?.usePartnerSources(),
  });

  // if the estimate is loading
  if (estimateLoading) {
    return <FullScreenLoading />;
  }

  // if there was an error loading the estimate
  if (estimateHasError) {
    return <ErrorPage />;
  }

  // if there is no estimate
  if (!estimate || !estimateKey) {
    return null;
  }

  // if enhanced education (about, pricing, estimator, application pages)
  if (estimate.getSourceIsEnhancedEducation()) {
    navItems = navItemsHeiEnhancedEducation;
    defaultPage = Page.HEI_ABOUT;
  }

  // if no page is given, redirect to the default route
  if (!page) {
    return (
      <Redirect
        to={generateUrl(nullthrows(pageRouteMap[defaultPage]).path, {
          estimateKey,
        })}
      />
    );
  }

  // if the page is not included in the pageContentMap, redirect to the default route
  if (!Object.keys(pageContentMap).includes(page)) {
    logInfo({
      eventType: 'EstimatesController',
      detail: {
        message: 'No "page" found in the "pageContentMap" object',
        page,
      },
    });
    return (
      <Redirect
        to={generateUrl(nullthrows(pageRouteMap[defaultPage]).path, {
          estimateKey,
        })}
      />
    );
  }

  // If the estimate is expired
  if (estimate.getIsExpired() === true) {
    return <ErrorPage errorType={ErrorType.OfferExpired} />;
  }

  // Decide which kind of header to show based on the estimate source
  const headerType =
    estimate.getSourceIsEnhancedEducation() || estimate.getSourceIsPrequal()
      ? HeaderType.Progress
      : HeaderType.Sequence;

  const navProps: NavProps = {
    estimateKey,
    estimate,
  };

  // Decide which banner to show
  if (page === Page.HEI_APPLICATION) {
    bannerProps = {};
  } else if (visitor?.hasActiveDocket) {
    const [message, actionText] = getResumeApplicationBannerText(visitor);
    if (actionText) {
      const isVisible = true;
      bannerProps = getResumeApplicationBannerProps(isVisible, {
        message: message ?? undefined,
        actionText,
        getHeiApplicationUrl,
      });
    }
  } else if (hasNewOffer && !visitorDataLoading && !visitor?.isPartner) {
    bannerProps = {
      messageType: BannerMessageType.RecentOffer,
      hasClose: true,
      actionProps: {
        estimate,
        estimateKey,
        history,
      },
    };
  } else if (estimate.preliminary) {
    bannerProps = {
      messageType: BannerMessageType.Preliminary,
    };
  }

  if (PageComponent) {
    return (
      <>
        <PageComponent
          page={page}
          history={history}
          location={location}
          navProps={navProps}
          bannerProps={bannerProps}
          navItems={navItems}
          estimateKey={estimateKey}
          estimate={estimate}
          pricingRange={pricingRange}
          calendar={calendar}
          subHeaderProps={{
            calendar,
            content: SubHeaderContentType.CallUs,
            styleAlign: DirectionAndPlacement.Left,
          }}
          headerType={headerType}
        />
        <ContactModal applicant={applicant} estimateKey={estimateKey} />
      </>
    );
  }

  return null;
}
