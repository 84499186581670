import styled, { css } from 'styled-components';
import { ButtonStyles, Container, HelpTextStyles } from '@pointdotcom/pds';
import { PolicyHeaderStyle } from 'components/ContactForm/styles';
import { FollowSectionStyle } from 'components/FollowSection/styles';

export const CTAContainerStyle = styled(Container).attrs({ className: 'CTAContainerStyle' })`
  padding-block: 1rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.7rem;
`;

export const ContactPageWrapperStyle = styled.div.attrs({
  className: 'ContactPageWrapperStyle',
})<{
  prequalContactFlagEnabled?: boolean;
}>`
  .topSection ${HelpTextStyles.HelpTextStyle}, ${PolicyHeaderStyle} {
    overflow: visible;
    position: relative;
    display: none;
  }
  ${FollowSectionStyle} {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.responsive.largestMobileScreen}) {
    .topSection ${HelpTextStyles.HelpTextStyle}, ${PolicyHeaderStyle} {
      display: block;
    }
    ${PolicyHeaderStyle} {
      &:before {
        content: '*';
        display: block;
        position: absolute;
        left: -0.7em;
      }
    }
    ${FollowSectionStyle} {
      display: block;
    }

    ${({ prequalContactFlagEnabled }) => {
      return (
        prequalContactFlagEnabled &&
        css`
          form {
            ${ButtonStyles.ButtonBaseStyle} {
              display: none;
            }
          }
        `
      );
    }}
  }
`;
