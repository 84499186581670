import React from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import Player from 'video.js/dist/types/player';
import {
  Button,
  DirectionAndPlacement,
  IconName,
  Size,
  Style,
  useIsMobile,
} from '@pointdotcom/pds';
import AuthenticatedDashboard from 'components/AuthenticatedDashboard';
import DashboardMainHeader from 'components/DashboardMainHeader';
import { dashboardTaskCrumbs } from 'components/DashboardTasksCrumbs';
import FullScreenLoading from 'components/FullScreenLoading';
import MainFooter from 'components/MainFooter';
import { TagPage, Workflow } from 'components/TagPage';
import ErrorPage from 'containers/ErrorPage';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { useHistory } from 'containers/routerHelpers';
import DashboardModel from 'models/DashboardModel';
import { useGetQuizQuestionsQuery, useGetQuizResultsQuery } from 'services/api/homeownerApi';
import { ApplicantStatus } from 'services/apiTypes/quizTypes';
import { Products } from 'store/constants';
import {
  backToQuizFromVideo,
  closeVideo,
  getVideoIsOpen,
  inVideoFromQuiz,
  openQuiz,
  openVideo,
} from 'store/productQuiz';
import VideoPlayerModal from '../../components/VideoPlayer/VideoPlayerModal';
import QuizModal from './QuizFlow/QuizModal';
import QuizIntroduction from './QuizIntroduction';
import QuizResult from './QuizResult';
import i18n from './i18n';
import * as styles from './styles';

const ProductQuizPage = ({ dashboard }: { dashboard: DashboardModel | null }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLandscape } = useIsMobile();
  const fromQuiz = useSelector(inVideoFromQuiz);
  const videoIsOpen = useSelector(getVideoIsOpen);

  const {
    data: quizData,
    error: questionApiError,
    isLoading: questionsLoading,
  } = useGetQuizQuestionsQuery();

  const {
    data: applicantQuiz,
    error: answerApiError,
    isLoading: applicantLoading,
  } = useGetQuizResultsQuery();

  if (questionsLoading || applicantLoading) {
    return <FullScreenLoading />;
  }
  if (questionApiError || answerApiError) {
    return <ErrorPage />;
  }

  if (!dashboard) {
    return null;
  }

  const scheduleCallUrl = generateUrlFromPage(
    pages.PREQUAL_SCHEDULE,
    { product: Products.HEI },
    dashboard.getScheduleCallParams()
  );

  const handleVideoClick = (videoPosition?: number) => {
    dispatch(openVideo(videoPosition || 0));
  };

  const handleDashboardClick = () => {
    const dashboardPage = generateUrlFromPage(pages.DASHBOARD);
    history.push(dashboardPage);
  };

  const handleOpenQuiz = () => {
    dispatch(openQuiz());
  };

  const hasQuizResult =
    applicantQuiz?.applicantStatus === ApplicantStatus.Passed ||
    applicantQuiz?.applicantStatus === ApplicantStatus.Failed;
  const QuizContent = hasQuizResult ? QuizResult : QuizIntroduction;

  const onQuizBack = () => {
    dispatch(backToQuizFromVideo());
  };

  const videoPlayerModalUpperContent = fromQuiz ? (
    <styles.HeaderButtonContainerStyle isLandscape={isLandscape}>
      <Button
        styleType={Style.Tertiary}
        iconStyleAlign={DirectionAndPlacement.Left}
        iconType={IconName.ChevronLeft}
        styleSize={Size.Small}
        onClick={onQuizBack}
      >
        Return to the Quiz
      </Button>
    </styles.HeaderButtonContainerStyle>
  ) : null;

  const videoPlayerModalLowerContent = !fromQuiz ? (
    <styles.FooterButtonContainerStyle isLandscape={isLandscape}>
      <Button minWidth="100%" onClick={onQuizBack}>
        {i18n.startTheQuiz}
      </Button>
      <Button styleType={Style.Tertiary} onClick={() => dispatch(closeVideo())}>
        {i18n.close}
      </Button>
    </styles.FooterButtonContainerStyle>
  ) : null;

  const onClose = () => {
    dispatch(fromQuiz ? backToQuizFromVideo() : closeVideo());
  };

  const mobileChapterTitle = fromQuiz ? i18n.jumpToTheAnswer : i18n.jumpToSection;

  const onHandleFullScreenVideoControls = ({ player }: { player: Player }) => {
    const controlBar = player.getChild('ControlBar');
    if (!controlBar) {
      return;
    }

    // Add the quiz button
    const position = fromQuiz ? 0 : undefined;
    const quizButtonText = fromQuiz ? i18n.backToQuiz : i18n.startQuiz;
    const quizButtonName = 'QuizButton';
    // removing any existing quiz buttons

    controlBar.children().forEach((controlBarButton) => {
      if (controlBarButton.name() === quizButtonName) {
        controlBar.removeChild(controlBarButton);
      }
    });
    const quizButton = controlBar.addChild(
      quizButtonName,
      {
        controlText: quizButtonText,
        className: `vjs-visible-text ${fromQuiz ? 'point-back-to-quiz' : 'point-start-quiz'}`,
      },
      position
    );

    const handleClick = () => {
      player.exitFullscreen();
      dispatch(backToQuizFromVideo());
    };

    quizButton.on('click', handleClick);
    quizButton.on('touchstart', handleClick);
  };

  const conditionalHeader = (
    <DashboardMainHeader
      dashboard={dashboard}
      crumbProps={{ navItems: dashboardTaskCrumbs, currentCrumb: i18n.subheaderBreadcrumb }}
    />
  );
  return (
    <styles.ProductQuizPageStyle>
      <Helmet title={i18n.productEducation} />
      <TagPage
        page={hasQuizResult ? 'HEI educational video: Quiz results' : 'HEI educational video'}
        workflow={Workflow.ProductVideoAndQuiz}
      />
      {conditionalHeader}
      {quizData && <QuizModal quizData={quizData} />}
      <VideoPlayerModal
        isOpen={videoIsOpen}
        upperContent={videoPlayerModalUpperContent}
        lowerContent={videoPlayerModalLowerContent}
        onClose={onClose}
        videoPlayerProps={{ mobileChapterTitle, onHandleFullScreenVideoControls }}
      />
      <QuizContent
        onVideoClick={handleVideoClick}
        scheduleCallUrl={scheduleCallUrl}
        onOpenQuizClick={handleOpenQuiz}
        onDashboardClick={handleDashboardClick}
      />
      <MainFooter />
    </styles.ProductQuizPageStyle>
  );
};

const ProductQuizPageWithAuth = () => {
  return (
    <AuthenticatedDashboard>
      {({ dashboard }) => <ProductQuizPage dashboard={dashboard} />}
    </AuthenticatedDashboard>
  );
};

export default ProductQuizPageWithAuth;
