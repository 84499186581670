import React, { forwardRef } from 'react';
import { ButtonClickFunc } from '@pointdotcom/pds';
import i18n from './i18n';
import * as styles from './styles';

interface VerificationErrorProps {
  issues: Array<string>;
  solutions: Array<string>;
  onManualReview: ButtonClickFunc;
}

const VerificationError = forwardRef<HTMLDivElement, VerificationErrorProps>(
  ({ issues, solutions, onManualReview }, ref) => {
    return (
      <styles.VerificationErrorStyle ref={ref}>
        <div>
          <div>
            <h5>{i18n.weHaveDetected}</h5>
            <ul>
              {issues.map((issue, index) => (
                <li key={index}>{issue}</li>
              ))}
            </ul>
          </div>
          <hr />
          <div>
            <h5>{i18n.howToProceed}</h5>
            <ul>
              {solutions.map((solution, index) => (
                <li key={index}>{solution}</li>
              ))}
            </ul>
          </div>
        </div>
        <aside>
          {i18n.doYouBelieve}
          <styles.ErrorButtonStyle onClick={onManualReview}>
            {i18n.requestAManualReview}
          </styles.ErrorButtonStyle>
        </aside>
      </styles.VerificationErrorStyle>
    );
  }
);

export default VerificationError;
