import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  DirectionAndPlacement,
  HelpTextAnimated,
  Style,
  messagingI18n,
} from '@pointdotcom/pds';
import { pages } from 'containers/helpers';
import ModalPage, { FlexFlow } from 'containers/prequal/ModalPage';
import { useRedirectToBeginning } from 'containers/prequal/hooks';
import { usePrequalApplication } from 'containers/prequal/hooks/usePrequalApplication';
import { useProduct } from 'containers/prequal/hooks/useProduct';
import { getPrequalPageFlow } from 'containers/prequal/productPageFlow';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { useGetFicoRangesQuery } from 'services/api/prequalApi';
import { answerCreditScore } from 'store/applicant';
import i18n from './i18n';

const FicoList = ({ ficoOptions, onChange, answer }) =>
  ficoOptions
    ? ficoOptions.map(({ label, key }, i) => (
        <Checkbox
          label={label}
          key={label}
          value={key}
          checkVal={answer}
          onChange={onChange}
          last={i === ficoOptions.length - 1}
          focused={i === 0}
        />
      ))
    : null;

const CreditScore = () => {
  useRedirectToBeginning();
  const { product } = useProduct();
  const { totalPages, flowIndex } = getPrequalPageFlow(product, pages.PREQUAL_CREDIT_SCORE);
  const { data: ficoOptions, error: ficoError } = useGetFicoRangesQuery();
  const existing = useSelector((state) => state.applicant?.ficoRange);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState(existing || '');
  const [formError, setFormError] = useState(null);
  const dispatch = useDispatch();
  const prequalContactFlagEnabled = useFeatureFlag(FeatureFlag.PrequalContact) === 'test';
  const { postPrequalApplication, processApplicationResponse } = usePrequalApplication();

  useEffect(() => {
    if (ficoError) {
      setFormError(i18n.oopsSoSorry);
    }
  }, [ficoError]);

  const clearErrors = () => {
    setFormError(null);
  };

  const handleChange = (e, { value }) => {
    clearErrors();
    setAnswer(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    try {
      setLoading(true);

      if (!answer) {
        setFormError(i18n.thisField);
        return;
      }

      // Save credit score and re-submit application
      dispatch(answerCreditScore(answer));
      const result = await postPrequalApplication();
      processApplicationResponse(result);
    } catch (error) {
      setFormError(messagingI18n.errors.formError);
    } finally {
      setLoading(false);
    }
  };

  let contextHelp = [
    {
      headline: i18n.whatCredit,
      body: i18n.pointTreats,
    },
    {
      headline: i18n.howLong,
      body: i18n.fundingTimelines,
    },
  ];

  if (prequalContactFlagEnabled) {
    contextHelp = [
      {
        headline: i18n.whatCredit,
        body: i18n.pointTreatsScore500,
      },
      {
        headline: i18n.whyDoesPoint,
        body: i18n.pointNeedsToKnowExtended,
      },
    ];
  }

  return (
    <ModalPage
      pageName={i18n.pageName}
      numIndicators={totalPages}
      currentIndicatorIndex={flowIndex}
      flexFlow={FlexFlow.Column}
      content={{
        title: i18n.title,
      }}
      contextHelp={contextHelp}
    >
      <form noValidate onSubmit={handleSubmit}>
        <FicoList ficoOptions={ficoOptions} onChange={handleChange} answer={answer} />
        <Button block type="submit" loading={loading}>
          {i18n.continue}
        </Button>
      </form>
      <HelpTextAnimated
        show={!!formError}
        styleMarginPosition={DirectionAndPlacement.Top}
        styleType={Style.Error}
      >
        {formError}
      </HelpTextAnimated>
    </ModalPage>
  );
};

export default CreditScore;
