import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  DirectionAndPlacement,
  Header,
  Modal,
  ModalProps,
  Size,
  messagingI18n,
} from '@pointdotcom/pds';
import ContactForm from 'components/ContactForm';
import { getSaveApplicantContactModalOpen, setSaveApplicantContactModalOpen } from 'store/general';
import { saveEstimate } from 'store/thunks/estimates';
import { Applicant } from 'types';
import i18n from './i18n';

const SaveApplicantContactModal = ({
  applicant,
  buttonText = i18n.saveAndContinue,
  modalProps,
  onSubmitValid = () => null,
}: {
  applicant: Applicant;
  buttonText?: string;
  onSubmitValid?: () => void;
  modalProps?: ModalProps;
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const { estimateKey } = useParams<{ estimateKey: string }>();
  const contactModalOpen = useSelector(getSaveApplicantContactModalOpen);
  const handleModalClose = () => {
    dispatch(setSaveApplicantContactModalOpen(false));
  };
  if (!applicant) {
    return null;
  }

  const handleSubmitValid = (
    _: unknown,
    { setLoading, estimateObjWithApplicant, setFormError }: TSFixMe
  ) => {
    (async () => {
      try {
        await dispatch(
          saveEstimate({
            estimateKey: estimateKey as TSFixMe,
            newEstimateData: estimateObjWithApplicant,
          })
        );
        dispatch(setSaveApplicantContactModalOpen(false));
        onSubmitValid();
      } catch (e) {
        setFormError(messagingI18n.errors.unknownError);
        setLoading(false);
      }
    })();
  };

  return (
    <Modal isOpen={contactModalOpen} onModalClose={handleModalClose} {...modalProps}>
      <div>
        <Header
          sideLines
          styleAlign={DirectionAndPlacement.Left}
          styleAlignMobile={DirectionAndPlacement.Left}
          styleSize={Size.Large}
          maxWidth="380px"
        >
          {i18n.enterYourDetails}
        </Header>
        <ContactForm
          buttonText={buttonText}
          firstName={applicant.firstName}
          lastName={applicant.lastName}
          phone={applicant.phone}
          email={applicant.email}
          onSubmitValid={handleSubmitValid}
        />
      </div>
    </Modal>
  );
};

export default SaveApplicantContactModal;
