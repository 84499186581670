export default {
  pageName: 'Contact Info',
  werePreparing: 'We’re preparing your offer.',
  youreAlmostDoneHeader: 'You’re almost done!',
  youreAlmostDone: 'You’re almost done! You’ll receive your offer on the next page.',
  youllReceive: 'You’ll receive your offer on the next page.',
  werePreparingRate: 'We’re preparing your rate.',
  youreAlmostDoneRate: 'You’re almost done! You’ll receive your rate on the next page.',
  reachYou: 'How can we reach you?',
  offerOnNextPage: "You'll receive your offer on the next page.",
  willSubmitting: 'Will submitting this form impact my credit score?',
  lastStep: 'Last Step',
  checkYourCreditScore:
    'We need to check your credit score to confirm your eligibility. This step <strong>will not affect your score</strong>. Please enter your contact details to continue.',
  noYourCredit:
    'No, your credit score will not be impacted because we are not making a “hard inquiry” to your credit report.',

  //isFirmOffer, isAltFirmOffer
  whyContactMe: 'Why do you need to contact me?',
  customerServiceTeam:
    'Point is a new type of financial product and we want to make sure our customers fully understand it. Our customer service team can answer all of your questions. We don’t sell your information to anyone or use it in any way other than to educate customers about our product.',
  canEligibilityFor: 'Can eligibility for a Point investment change?',
  yesYourEligibility:
    'Yes. Your eligibility is subject to final underwriting approval and verification of all information that you provide. You may not be eligible for the prescreened offer received in the mail if you no longer meet the selection criteria, inadequate documentation is provided during the application process, and/or if your credit report or title report reveal adverse information.',
  getMyRate: 'Get my rate',
  viewMyOffer: 'View my offer',
  prequalificationWillNot: 'Prequalification will %not% impact your credit score.',
  not: 'not',
  continue: 'Continue',
  confirmEligibility: 'Confirm my eligibility',
  byClickingShort:
    'By clicking the “%buttonText%” button, you agree to the %termsAndConditions% below.',
  byClicking:
    'By clicking the “%buttonText%” button, you agree to the %termsAndConditions% below. Please read the full terms and conditions before proceeding.%sup%',
  termsAndConditions: 'terms and conditions',

  // FeatureFlag.PrequalContact
  whyDoesPoint: 'Why does Point need my information?',
  weUnderstand:
    'We understand the importance of your information and only request it for critical reasons. We need your legal name to make your initial offer as accurate as possible, which involves a soft credit pull that will not impact your credit score. Additionally, we require your email address to share your initial offer and rate with you.',
};
