import { createSlice, current } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import OfferEstimateModel from 'models/OfferEstimateModel';
import PricingRangeModel from 'models/PriceRangeModel';
import { PricingRangeResponse } from 'services/apiTypes/prequalTypes';
import { RootState } from 'store';
import { getEstimate, getPricingRange, saveEstimate } from 'store/thunks/estimates';
import { Estimate } from 'types';
import { LoadState, OfferStatuses, Products } from './constants';

interface HEIState {
  estimate?: Estimate;
  pricingRangeEstimates?: PricingRangeResponse;
  pricingRangeLoadState?: LoadState;
  priceRangeSelectedIndex?: number;
  sortOrder?: number;
}

type PriceableProducts = Products.HEI;
interface EstimatesState {
  estimateLoadState?: LoadState;
  estimateError?: AxiosResponse; // status and statusText are used in component views
  sortOrder?: Array<Products>;
  [Products.HEI]: HEIState;
}

export const initialState: EstimatesState = {
  [Products.HEI]: {},
};

const estimatesSlice = createSlice({
  name: 'estimates',
  initialState,
  reducers: {
    setHEIEstimate: (state, { payload }) => {
      state[Products.HEI].estimate = payload;
    },
    setHEIPriceRangeSelectedIndex: (state, { payload }) => {
      state[Products.HEI].priceRangeSelectedIndex = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getEstimate.pending, (state) => {
      state.estimateLoadState = LoadState.Pending;
    });
    builder.addCase(getEstimate.fulfilled, (state, { payload }) => {
      state.estimateLoadState = LoadState.Fulfilled;
      state[Products.HEI].estimate = payload;
    });
    builder.addCase(getEstimate.rejected, (state, { payload }) => {
      state.estimateLoadState = LoadState.Rejected;
      state.estimateError = payload;
    });

    // HEI
    builder.addCase(getPricingRange.pending, (state) => {
      state[Products.HEI].pricingRangeLoadState = LoadState.Pending;
    });
    builder.addCase(getPricingRange.fulfilled, (state, { payload }) => {
      state[Products.HEI].pricingRangeEstimates = payload;
      state[Products.HEI].pricingRangeLoadState = LoadState.Fulfilled;
    });
    builder.addCase(getPricingRange.rejected, (state) => {
      state[Products.HEI].pricingRangeLoadState = LoadState.Rejected;
    });

    builder.addCase(saveEstimate.fulfilled, (state, { payload }) => {
      // the response from saveEstimate does not contain the entire estimate
      state[Products.HEI].estimate = { ...current(state)[Products.HEI].estimate, ...payload };
    });
  },
});

// HEI
export const getHEIRangeIsLoading = ({ estimates }: RootState): boolean => {
  return estimates[Products.HEI].pricingRangeLoadState === LoadState.Pending;
};

// gross - class alias to satisfy typescript
export class PricingRangeType extends PricingRangeModel {}
export const getPriceRangeModel = ({ estimates }: RootState): PricingRangeType | null =>
  Array.isArray(estimates[Products.HEI]?.pricingRangeEstimates)
    ? new PricingRangeModel({
        estimates: estimates[Products.HEI]?.pricingRangeEstimates,
      })
    : null;

export const getHEIPriceRangeSelectedIndex = ({ estimates }: RootState): number => {
  const heiStore = estimates[Products.HEI];

  if (heiStore?.priceRangeSelectedIndex !== undefined) {
    return heiStore.priceRangeSelectedIndex;
  }

  if (heiStore?.pricingRangeEstimates?.length) {
    return Math.ceil((heiStore.pricingRangeEstimates.length - 1) / 2);
  }

  return 0;
};

export const getRawHEIEstimate = ({ estimates }: RootState) => estimates[Products.HEI]?.estimate;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getHEIEstimateModel = (store: RootState): OfferEstimateModel | null => {
  const { estimates } = store;
  let estimate = estimates[Products.HEI]?.estimate;
  const pricingRangeEstimates = estimates[Products.HEI]?.pricingRangeEstimates;
  if (estimate) {
    const priceRangeSelectedIndex = getHEIPriceRangeSelectedIndex(store);
    if (pricingRangeEstimates) {
      const pricingFromRangeSelection = {
        ...pricingRangeEstimates[priceRangeSelectedIndex],
      };
      estimate = { ...estimate };
      if (pricingFromRangeSelection) {
        estimate.pricing = pricingFromRangeSelection;
      }
    }
    const estimateModel = new OfferEstimateModel(estimate);
    return estimateModel;
  }
  return null;
};

// All
export const getEstimatesAreLoading = ({ estimates }: RootState): boolean => {
  return estimates.estimateLoadState === LoadState.Pending;
};

export const getEstimatesLoadNotStarted = ({
  estimates: { estimateLoadState },
}: RootState): boolean => {
  return !estimateLoadState;
};

export const getEstimatesLoadComplete = ({
  estimates: { estimateLoadState },
}: RootState): boolean => {
  return estimateLoadState
    ? [LoadState.Fulfilled, LoadState.Rejected].includes(estimateLoadState)
    : false;
};

export const getEstimatesError = ({
  estimates: { estimateError },
}: RootState): AxiosResponse | undefined => {
  return estimateError;
};

export const getEstimatesHaveError = ({ estimates }: RootState): boolean => {
  return estimates.estimateLoadState === LoadState.Rejected;
};

export const getEstimatesByProduct = ({
  estimates,
}: RootState): Partial<Record<PriceableProducts, EstimatesState[PriceableProducts]>> => {
  const productEstimates: {
    product: PriceableProducts;
    estimate: EstimatesState[PriceableProducts];
  }[] = [{ product: Products.HEI, estimate: estimates[Products.HEI] }];

  // Considers the order from the "order" URL param
  const products = productEstimates.map(({ product }) => product.toLowerCase());
  const productOrderFromParam: Array<string> | undefined = new URLSearchParams(
    window.location.search
  )
    ?.get('order')
    ?.split(',')
    ?.map((product) => product.toLowerCase())
    ?.filter((product) => products.includes(product));

  // const productOrderFromParam: Array<string> = [];

  // If there are items in the URL param, make a complete product order array
  const productOrderMapFromParam: Partial<Record<PriceableProducts, number>> =
    productOrderFromParam && productOrderFromParam.length > 0
      ? [
          ...productOrderFromParam,
          ...products.filter((product) => !productOrderFromParam.includes(product)),
        ]?.reduce((acc, item, i) => {
          return { ...acc, [item]: i };
        }, {})
      : {};

  // considers sortOrder value found in each estimate store,
  // reorders array then transforms into a {[product]: estimate} object
  return productEstimates
    .sort(
      ({ product: product1, estimate: estimate1 }, { product: product2, estimate: estimate2 }) => {
        const firstSort =
          productOrderMapFromParam[product1] !== undefined
            ? productOrderMapFromParam[product1]
            : estimate1?.sortOrder;
        const secondSort =
          productOrderMapFromParam[product2] !== undefined
            ? productOrderMapFromParam[product2]
            : estimate2?.sortOrder;
        return (firstSort as number) > (secondSort as number) ? 1 : -1;
      }
    )
    .reduce((acc, { product, estimate }) => {
      return { ...acc, [product]: estimate };
    }, {});
};

type EligibilityFilterFn = (s: OfferStatuses | undefined) => boolean | undefined;

export const getEstimatesByEligibility =
  (eligibility: OfferStatuses | EligibilityFilterFn) =>
  (store: RootState): Array<{ product: PriceableProducts; estimate: Estimate | undefined }> => {
    const estimates = getEstimatesByProduct(store);
    return (Object.keys(estimates) as PriceableProducts[])
      .filter((productKey) => {
        const estimateItem = estimates[productKey];
        if (typeof eligibility === 'function') {
          return eligibility(estimateItem?.estimate?.status);
        }
        return estimateItem?.estimate?.status === eligibility;
      })
      .map((productKey) => ({
        product: productKey,
        estimate: estimates[productKey]?.estimate,
      }));
  };

export const getEligibleEstimates = (
  store: RootState
): Array<{ product: PriceableProducts; estimate: Estimate | undefined }> => {
  return getEstimatesByEligibility(OfferStatuses.Eligible)(store);
};

export const getIneligibleEstimates = (
  store: RootState
): Array<{ product: Products; estimate: Estimate | undefined }> => {
  // BE returns OfferStatuses.Rejected and OfferStatuses.Ineligible,
  // so status !== OfferStatuses.Eligible is used here to normalize
  return getEstimatesByEligibility((status) => status && status !== OfferStatuses.Eligible)(store);
};

export function getEstimateModelByProduct(
  product?: undefined
): (store: RootState) => Partial<Record<Products, OfferEstimateModel>>;

// eslint-disable-next-line no-redeclare
export function getEstimateModelByProduct(product?: Products) {
  return (
    store: RootState
  ): OfferEstimateModel | Partial<Record<Products, OfferEstimateModel>> | undefined => {
    const eligibleEstimates = getEligibleEstimates(store);

    if (!eligibleEstimates?.length) {
      return {};
    }

    const productEstimateMap: Partial<Record<Products, typeof getHEIEstimateModel>> = {
      [Products.HEI]: getHEIEstimateModel,
    };

    const productEstimateModelMap: Partial<Record<Products, OfferEstimateModel>> = eligibleEstimates
      .filter(({ product: productKey }) => (product ? product === productKey : true))
      .reduce((acc, item) => {
        const { product: productKey } = item;
        const func = productEstimateMap[productKey];
        return { ...acc, [productKey]: func ? func(store) : null };
      }, {});

    return product ? productEstimateModelMap[product] : productEstimateModelMap;
  };
}

export const getEstimatesMaxAmount = (store: RootState): number => {
  const models = getEstimateModelByProduct()(store);
  const heiModel = models[Products.HEI] as OfferEstimateModel;
  return heiModel?.getMaxOptionAmount() || 0;
};

export const getEstimateByEstimateKey =
  (estimateKey: string) =>
  (store: RootState): Estimate | undefined => {
    const estimatesByProduct = getEstimatesByProduct(store);
    let found;
    (Object.keys(estimatesByProduct) as PriceableProducts[]).forEach((product) => {
      const estimateStore = estimatesByProduct[product];
      if (estimateKey === estimateStore?.estimate?.key) {
        found = {
          ...estimateStore,
          product,
        };
      }
    });
    return found;
  };

export const getPricingRangeIsLoading = ({ estimates }: RootState): boolean => {
  return estimates[Products.HEI].pricingRangeLoadState === LoadState.Pending;
};

export const getPricingRangeEstimateLoadNotStarted = (state: RootState) =>
  !state.estimates[Products.HEI].pricingRangeLoadState;

export const getPricingRangeHasError = ({ estimates }: RootState): boolean => {
  return estimates[Products.HEI].pricingRangeLoadState === LoadState.Rejected;
};

export default estimatesSlice.reducer;

export const { setHEIEstimate, setHEIPriceRangeSelectedIndex } = estimatesSlice.actions;
