import React from 'react';
import dayjs from 'dayjs';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Header, QueryComponent, Size, SplashText } from '@pointdotcom/pds';
import AuthenticatedDashboard from 'components/AuthenticatedDashboard';
import DashboardMainHeader from 'components/DashboardMainHeader';
import MainFooter from 'components/MainFooter';
import DashboardActiveTasks from 'containers/DashboardPage/DashboardActiveTasks';
import { Page, getPathFromPage } from 'containers/helpers';
import DashboardModel from 'models/DashboardModel';
import { HosDashboardTask } from 'services/apiTypes/homeownerTypes';
import { TaskStatus } from 'services/apiTypes/taskTypes';
import i18n from './i18n';
import * as styles from './styles';

function CompletedTasks({ tasks }: { tasks: HosDashboardTask[] }) {
  return tasks.length ? (
    <styles.CompletedTasksSectionStyle>
      <Container>
        <Header>{i18n.completed}</Header>
      </Container>
      <Container mobileCollapse>
        <ul>
          {tasks.map((item) => (
            <li key={item.title}>
              <styles.ReadOnlyCheckboxCheckedStyle />
              <span>{item.title}</span>
              <styles.CompletedDateStyle>
                {item.submittedAt ? dayjs(item.submittedAt).format('MMMM D, YYYY') : null}
              </styles.CompletedDateStyle>
            </li>
          ))}
        </ul>
      </Container>
    </styles.CompletedTasksSectionStyle>
  ) : null;
}

const DashboardTasksListPage = ({ dashboard }: { dashboard: DashboardModel }) => {
  const openTasks = dashboard.getTasks({ status: TaskStatus.Active });
  const completedTasks = dashboard.getTasks({ status: TaskStatus.Submitted });
  const hasOpenItems = openTasks.length > 0;
  const hasCompletedItems = completedTasks.length > 0;
  const hasAnyItems = hasOpenItems || hasCompletedItems;

  return (
    <>
      <styles.DashboardTasksPageStyle>
        <Helmet title={i18n.pageTitle} />
        <DashboardMainHeader
          dashboard={dashboard}
          crumbProps={{ currentCrumb: i18n.subheaderBreadcrumb }}
        />
        <styles.PageHeaderStyle
          hasOpenItems={hasOpenItems}
          hasAnyItems={hasAnyItems}
          hasCompletedItems={hasCompletedItems}
        >
          <Container>
            <div>
              <Header styleSize={Size.Splash} noMargin>
                {i18n.yourTasks}
              </Header>
              {!hasOpenItems && (
                <styles.TaskSubHeaderStyle
                  as={hasAnyItems ? SplashText : Header}
                  styleSize={Size.Medium}
                >
                  {i18n.weDontNeed}
                  {!hasCompletedItems && (
                    <>
                      <br />
                      <Link to={getPathFromPage(Page.DASHBOARD)!}>
                        <QueryComponent breakpoint="400px" queriedComponent={<>{i18n.backTo}</>}>
                          {i18n.goBack}
                        </QueryComponent>
                      </Link>
                    </>
                  )}
                </styles.TaskSubHeaderStyle>
              )}
            </div>
            <styles.TaskGraphicStyle hasCompletedItems={hasCompletedItems}>
              <QueryComponent
                queriedComponent={<img src="https://cdn.point.com/task-hills-mobile.png" alt="" />}
              >
                <img src="https://cdn.point.com/task-hills-desktop.png" alt="" />
              </QueryComponent>
            </styles.TaskGraphicStyle>
          </Container>
        </styles.PageHeaderStyle>
        {hasAnyItems && (
          <styles.PageContentStyle>
            {/* Active tasks */}
            {hasOpenItems && (
              <styles.ActiveTaskListStyle>
                {hasCompletedItems && (
                  <Container>
                    <Header>{i18n.open}</Header>
                  </Container>
                )}
                <DashboardActiveTasks openTasks={openTasks} />
              </styles.ActiveTaskListStyle>
            )}

            {/* Completed tasks */}
            {hasCompletedItems && <CompletedTasks tasks={completedTasks} />}
          </styles.PageContentStyle>
        )}
      </styles.DashboardTasksPageStyle>
      <MainFooter />
    </>
  );
};

const DashboardTasksListPageWithAuth = () => {
  return (
    <AuthenticatedDashboard>
      {({ dashboard }) => dashboard != null && <DashboardTasksListPage dashboard={dashboard} />}
    </AuthenticatedDashboard>
  );
};

export default DashboardTasksListPageWithAuth;
