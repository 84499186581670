import styled, { createGlobalStyle, css } from 'styled-components';
import { GlobalBaseFontSize, Size, Style, mixins } from '@pointdotcom/pds';

// App level theme vars

const basePadding = { desktop: '6.8vh', mobileMid: '16%', mobile: '14%' };

export const paddingConfig = {
  [Size.Small]: {
    section: { ...basePadding, desktop: '6vh' },
    hero: { ...basePadding, desktop: '4%' },
  },
  [Size.Default]: {
    section: basePadding,
    hero: { ...basePadding, desktop: '6%' },
  },
  [Size.Large]: {
    section: { ...basePadding, desktop: '7vh' },
    hero: { ...basePadding, desktop: '7%' },
  },
};

export const paddedSectionPadding = basePadding.desktop;
export const paddedSectionPaddingMobile = basePadding.mobile;
export const paddedSectionPaddingMobileMid = basePadding.mobileMid;

export const mainHeaders = {
  height: {
    default: '100px',
    mobile: '77px',
  },
};

export const appTheme = {
  mainHeaders,
};

// App level styles

export const AppResetStyle = createGlobalStyle`
  hr {
    background: ${({ theme }) => theme.line.color.default};
    height: 1px;
    width: 100%;
    display: block;
    border: none;
    &.vert {
      height: 100%;
      width: 1px;
    }
  }
`;

export const MainPageStyle = styled.div.attrs({ className: 'MainPageStyle' as string })`
  background: ${({ theme }) => theme.Color.White};
  transition: all 320ms ease-in-out;
`;

export const BodyCopyStyle = styled.div.attrs({ className: 'BodyCopyStyle' as string })<{
  bold?: boolean;
}>`
  font-size: inherit;
  margin: 0;
  line-height: 1.4em;
  margin-bottom: 1.5em;
  font-family: ${({ theme }) => theme.fonts.sansSerif.name};
  font-weight: ${({ bold }) => (bold ? 700 : 'normal')};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const BoldCopyStyle = styled.b.attrs({ className: 'BoldCopyStyle' as string })`
  font-family: ${({ theme }) => theme.fonts.sansSerif.name};
  font-weight: 700;
`;

export interface PaddedSectionCSSProps {
  styleSize?: Size;
}

export const PaddedSectionCSS = css<PaddedSectionCSSProps>`
  box-sizing: border-box;
  display: block;
  padding: ${({ styleSize = Size.Default }) =>
      paddingConfig[styleSize as keyof typeof paddingConfig].section.desktop}
    0;

  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding: ${({ styleSize = Size.Default }) =>
        paddingConfig[styleSize as keyof typeof paddingConfig].section.mobile}
      0;
  }

  @media only screen and (max-width: ${({ theme }) => theme.responsive.mobileMidBreakpoint}) {
    padding: ${({ styleSize = Size.Default }) =>
        paddingConfig[styleSize as keyof typeof paddingConfig].section.mobileMid}
      0;
  }
`;

export const PaddedSectionStyle = styled.section.attrs({
  className: 'PaddedSectionStyle' as string,
})<PaddedSectionCSSProps>`
  ${PaddedSectionCSS}
`;

export const PaddedFooterStyle = styled.footer.attrs({
  className: 'PaddedFooterStyle' as string,
})<PaddedSectionCSSProps>`
  ${PaddedSectionCSS}
`;

export const HeroContainerStyle = styled(PaddedSectionStyle).attrs({
  className: 'HeroContainerStyle' as string,
})<{ styleSize?: Size }>`
  padding: ${({ styleSize = Size.Default }) =>
      paddingConfig[styleSize as keyof typeof paddingConfig].hero.desktop}
    0;

  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    padding: ${({ styleSize = Size.Default }) =>
        paddingConfig[styleSize as keyof typeof paddingConfig].hero.mobile}
      0;
  }

  @media only screen and (max-width: ${({ theme }) => theme.responsive.mobileMidBreakpoint}) {
    padding: ${({ styleSize = Size.Default }) =>
        paddingConfig[styleSize as keyof typeof paddingConfig].hero.mobileMid}
      0;
  }
`;

const MobileSeparatorCSS = css`
  border-top: 1px solid ${({ theme }) => theme.line.color.default};
  padding-top: 5%;
`;

interface SplashCopySerifCSSProps {
  maxWidth?: string;
  noMargin?: boolean;
  centered?: boolean;
  centeredMobile?: boolean;
  mobileSeparator?: boolean;
}

export const SplashCopySerifCSS = css<SplashCopySerifCSSProps>`
  line-height: 1.4em;
  &:last-child {
    margin-bottom: 0;
  }
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  margin: 0 0 ${({ noMargin }) => (noMargin ? '0' : '5%')};
  font-weight: normal;
  font-family: ${({ theme }) => theme.fonts.serif.name};
  font-size: ${({ theme }) =>
    `${
      22 / parseInt(theme.globals.baseFontSize[GlobalBaseFontSize.Default] || '16', 10)
    }rem`}; // TODO: fix this "|| '16'" in PDS
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  @media only screen and (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    font-size: ${({ theme }) =>
      `${
        18 / parseInt(theme.globals.baseFontSize[GlobalBaseFontSize.Default] || '16', 10)
      }rem`}; // TODO: fix this "|| '16'" in PDS
    text-align: ${({ centeredMobile }) => {
      if (centeredMobile) {
        return 'center';
      }

      return 'left';
    }};
    ${({ mobileSeparator }) => (mobileSeparator ? MobileSeparatorCSS : null)};
  }
`;

export const SplashCopySerifStyle = styled(BodyCopyStyle).attrs({
  className: 'SplashCopySerifStyle' as string,
})<SplashCopySerifCSSProps>`
  ${SplashCopySerifCSS}
`;

export interface BorderedSectionStyleProps {
  styleType?: Style;
}
export const BorderedSectionStyle = styled(PaddedSectionStyle).attrs({
  className: 'BorderedSectionStyle' as string,
})<BorderedSectionStyleProps>`
  border-width: 0;
  border-style: solid;
  border-color: ${({ theme, styleType }) =>
    styleType === Style.Inverted ? theme.line.color.invertedSubtle : theme.line.color.default};
  border-top-width: 1px;
`;

export const UnderstatedSectionStyle = styled.div.attrs({
  className: 'UnderstatedSectionStyle' as string,
})`
  color: ${({ theme }) => theme.Color.Gray4};
  a,
  button {
    color: inherit;
    ${mixins.desktopHoverCondition()} {
      &:hover {
        color: ${({ theme }) => theme.Color.PointBlack};
      }
    }
  }
`;

export const InnerButtonStyle = styled.button`
  ${mixins.appearance('none')}
  transition: all 180ms ease-in-out;
  color: inherit;
  border: 1px solid ${({ theme }) => theme.line.color.default};
  padding: 1em 1.8em;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  ${mixins.desktopHoverCondition()} {
    &:hover {
      border-color: ${({ theme }) => theme.Color.PointBlack};
    }
  }
`;
