import * as React from 'react';
import nullthrows from 'nullthrows';
import { ProgressLoader, Size, Style, templatedString } from '@pointdotcom/pds';
import { getHomeownerHost } from 'lib/hosts';
import { DeleteFileButton, UploadedFileItemProps } from './UploadedFileItem';
import i18n from './i18n';
import * as styles from './styles';

export default function UploadedFileItemPreview({
  taskId,
  taskFile,
  upload,
  isDeleting,
  deleteFileError,
  onDeleteFile,
  onFileClick,
}: UploadedFileItemProps) {
  if (taskFile == null && upload == null) {
    throw new Error('Must have either `taskFile` or `upload`');
  }

  const fileName = nullthrows(taskFile?.fileName ?? upload?.file.name);
  const uploadError = upload?.error;
  const showProgress = upload != null && uploadError == null && !upload.uploadFinished;
  const errorMessage = uploadError?.message ?? deleteFileError;
  const hasError = !!errorMessage;
  const isClickable = onFileClick != null;

  return (
    <styles.UploadedFilePreviewItemStyle hasError={hasError} isDeleting={isDeleting}>
      <styles.UploadedFilePreviewNameStyle>{fileName}</styles.UploadedFilePreviewNameStyle>
      <styles.UploadedFilePreviewSpaceStyle>
        <styles.UploadedFilePreviewStyle
          hasError={hasError}
          isClickable={isClickable}
          onClick={isClickable ? () => onFileClick(fileName) : undefined}
          aria-label={
            isClickable
              ? templatedString({ template: i18n.showPreviewOf, values: { fileName } })
              : undefined
          }
        >
          {showProgress && (
            <ProgressLoader
              styleType={Style.Primary}
              styleSize={Size.Default}
              progress={upload.progress}
            />
          )}
          {hasError && (
            <styles.UploadedFilePreviewErrorStyle>
              {errorMessage}
            </styles.UploadedFilePreviewErrorStyle>
          )}
          {taskFile?.fileId != null && (
            <styles.UploadedFilePreviewImageStyle
              style={{
                backgroundImage: `url("${getHomeownerHost()}/task/${taskId}/file/${taskFile.fileId}/preview?width=${200}&height=${560}")`,
              }}
            />
          )}
        </styles.UploadedFilePreviewStyle>
      </styles.UploadedFilePreviewSpaceStyle>
      {!showProgress && (taskFile != null || hasError) && (
        <styles.UploadedFilePreviewButtonRowStyle>
          <DeleteFileButton
            onClick={onDeleteFile}
            fileName={fileName}
            hasError={hasError}
            deleting={isDeleting}
          />
        </styles.UploadedFilePreviewButtonRowStyle>
      )}
    </styles.UploadedFilePreviewItemStyle>
  );
}
