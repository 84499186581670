import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  DirectionAndPlacement,
  HelpText,
  LinkButton,
  PolicyType,
  TemplatedText,
  messagingI18n,
} from '@pointdotcom/pds';
import ContactForm, { ContactFormField, ContactFormRef } from 'components/ContactForm';
import FollowSection from 'components/FollowSection';
import { pages } from 'containers/helpers';
import ModalPage, { FlexFlow } from 'containers/prequal/ModalPage';
import { useProduct, useRedirectToBeginning } from 'containers/prequal/hooks';
import { usePrequalApplication } from 'containers/prequal/hooks/usePrequalApplication';
import { getPrequalPageFlow } from 'containers/prequal/productPageFlow';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import store, { RootState } from 'store';
import { answerContactInfo } from 'store/applicant';
import { Products } from 'store/constants';
import { APPLICATION_VALIDATION_CODES, PREQUAL_FLOW_TYPE } from 'types';
import i18n from './i18n';
import * as styles from './styles';

const Contact = () => {
  useRedirectToBeginning();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<typeof store.dispatch>();
  const { product = Products.HEI } = useProduct();
  const applicant = useSelector((state: RootState) => state.applicant);
  const { totalPages, flowIndex } = getPrequalPageFlow(product, pages.PREQUAL_CONTACT_INFO);
  const formRef = useRef<ContactFormRef>(null);
  const policyHeaderRef = useRef<HTMLHeadingElement>(null);
  const { postPrequalApplication, processApplicationResponse } = usePrequalApplication();
  const prequalContactFlagEnabled = useFeatureFlag(FeatureFlag.PrequalContact) === 'test';

  const isDirectMail = !!useSelector(
    (state: RootState) => state.prequalApplication?.directMailData
  );
  const isFirmOffer = useSelector(
    (state: RootState) => state.prequalApplication?.flow === PREQUAL_FLOW_TYPE.FIRM_OFFER
  );
  const isAltFirmOffer = useSelector(
    (state: RootState) => state.prequalApplication?.flow === PREQUAL_FLOW_TYPE.ALT_FIRM_OFFER
  );

  const handleSubmitValid: React.ComponentProps<typeof ContactForm>['onSubmitValid'] = async (
    e,
    { setFormError, setValidationError, value }
  ) => {
    setFormError(null);
    dispatch(answerContactInfo(value));
    try {
      setLoading(true);
      const result = await postPrequalApplication();
      processApplicationResponse(result);
    } catch (error: TSFixMe) {
      switch (error.message) {
        case APPLICATION_VALIDATION_CODES.INVALID_FIRST_NAME: {
          setValidationError(ContactFormField.FirstName);
          break;
        }
        case APPLICATION_VALIDATION_CODES.INVALID_LAST_NAME: {
          setValidationError(ContactFormField.LastName);
          break;
        }
        case APPLICATION_VALIDATION_CODES.INVALID_EMAIL: {
          setValidationError(ContactFormField.EmailAddress);
          break;
        }
        case APPLICATION_VALIDATION_CODES.INVALID_PHONE_NUMBER: {
          setValidationError(ContactFormField.PhoneNumber);
          break;
        }
        default: {
          // Any other failure is a generic error message
          setFormError(messagingI18n.errors.formError);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  let title = prequalContactFlagEnabled ? i18n.youreAlmostDoneHeader : i18n.werePreparing;
  let subtitle = prequalContactFlagEnabled ? i18n.youllReceive : i18n.youreAlmostDone;
  let buttonText = i18n.viewMyOffer;
  let policies: React.ComponentProps<typeof ContactForm>['policies'] = true;

  let contextHelp = prequalContactFlagEnabled
    ? [
        {
          headline: i18n.whyDoesPoint,
          body: i18n.weUnderstand,
        },
      ]
    : [
        {
          headline: i18n.willSubmitting,
          body: i18n.noYourCredit,
        },
      ];

  if (isFirmOffer) {
    // Omit the credit report policy in this case - https://pointdf.atlassian.net/browse/PPC-1493
    policies = [PolicyType.Contact, PolicyType.Share, PolicyType.ECES];
    title = i18n.reachYou;
    subtitle = i18n.offerOnNextPage;
    buttonText = i18n.continue;
    contextHelp = [
      {
        headline: i18n.whyContactMe,
        body: i18n.customerServiceTeam,
      },
    ];
  } else if (isAltFirmOffer) {
    title = i18n.lastStep;
    subtitle = i18n.checkYourCreditScore;
    buttonText = i18n.confirmEligibility;
    contextHelp = [
      {
        headline: i18n.whyContactMe,
        body: i18n.customerServiceTeam,
      },
      {
        headline: i18n.willSubmitting,
        body: i18n.noYourCredit,
      },
      {
        headline: i18n.canEligibilityFor,
        body: i18n.yesYourEligibility,
      },
    ];
  }

  const hiddenFields = [];
  if (isDirectMail) {
    if (applicant.firstName) {
      hiddenFields.push(ContactFormField.FirstName);
    }
    if (applicant.lastName) {
      hiddenFields.push(ContactFormField.LastName);
    }
  }

  const handleFloatingCTAClick = () => {
    formRef.current?.submit();
  };

  const handleTermsButtonClick = () => {
    policyHeaderRef.current?.scrollIntoView?.({
      behavior: 'smooth',
      block: 'start',
    });
  };

  let subContent = null;
  if (prequalContactFlagEnabled) {
    subContent = (
      <HelpText>
        <TemplatedText
          values={{
            buttonText,
            termsAndConditions: () => (
              <LinkButton onClick={handleTermsButtonClick}>{i18n.termsAndConditions}</LinkButton>
            ),
            sup: () => <sup>*</sup>,
          }}
        >
          {prequalContactFlagEnabled ? i18n.byClickingShort : i18n.byClicking}
        </TemplatedText>
      </HelpText>
    );
  }

  return (
    <styles.ContactPageWrapperStyle prequalContactFlagEnabled={prequalContactFlagEnabled}>
      <ModalPage
        pageName={i18n.pageName}
        numIndicators={totalPages}
        currentIndicatorIndex={flowIndex}
        flexFlow={FlexFlow.Column}
        content={{ title, subtitle, subContent }}
        contextHelp={contextHelp}
      >
        <ContactForm
          showPolicyHeader={prequalContactFlagEnabled}
          policyHeaderRef={policyHeaderRef}
          focused={ContactFormField.FirstName}
          hiddenFields={hiddenFields}
          firstName={applicant.firstName}
          lastName={applicant.lastName}
          policies={policies}
          onSubmitValid={handleSubmitValid}
          buttonText={buttonText}
          ref={formRef}
          loading={loading}
          helpText={
            <TemplatedText values={{ not: <strong>{i18n.not}</strong> }}>
              {i18n.prequalificationWillNot}
            </TemplatedText>
          }
        />
        {prequalContactFlagEnabled && (
          <FollowSection show placement={DirectionAndPlacement.Bottom}>
            <styles.CTAContainerStyle>
              <Button onClick={handleFloatingCTAClick} loading={loading}>
                {i18n.viewMyOffer}
              </Button>
              <HelpText styleAlign={DirectionAndPlacement.Center} noMargin>
                <TemplatedText values={{ not: <strong>{i18n.not}</strong> }}>
                  {i18n.prequalificationWillNot}
                </TemplatedText>
              </HelpText>
            </styles.CTAContainerStyle>
          </FollowSection>
        )}
      </ModalPage>
    </styles.ContactPageWrapperStyle>
  );
};

export default Contact;
